import axios from "axios";
import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "Utils";
import logo from "assets/image/ichilli_logo.png";
import Input from "components/Input";
import AuthWithFbButton from "components/AuthWithFbButton";
import MyModal from "components/Modal";
import Swal from "sweetalert2";

const SignIn = ({ setIsUserLoggedIn }) => {
  const [ForgotPass, setForgotPass] = useState(false);
  const [showTermsModel, setShowTermsModel] = useState(false);
  const [showPolicyModel, setShowPolicyModel] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [inputError, setInputError] = useState({});
  const [rememberMe, setRememberMe] = useState(false);
  const [isNoPlan, setNoPlan] = useState(false);
  const history = useHistory();

  const fetchData = () => {
    axios
      .post(baseURL + "loginweb", {
        email: formData.email,
        pass: formData.password,
        deviceToken: "",
        deviceType: "web",
        againlogin: "0",
      })
      .then((response) => {
        // console.log(response);
        if (
          response?.data?.Status == "success" &&
          !response?.data?.Result?.SubscriberUser?.PlanName
        ) {
          setNoPlan(true);
        } else if (
          response?.data?.Status == "success" &&
          response?.data?.access_token
        ) {
          if (rememberMe) {
            localStorage.setItem(
              "USERID",
              response?.data?.Result?.UserDetail?.UserId
            );
            sessionStorage.removeItem("USERID");
          } else {
            localStorage.removeItem("USERID");
            sessionStorage.setItem(
              "USERID",
              response?.data?.Result?.UserDetail?.UserId
            );
          }
          localStorage.setItem(
            "USER",
            JSON.stringify(response?.data?.Result?.UserDetail)
          );
          localStorage.setItem(
            "SubscriberUser",
            JSON.stringify(response?.data?.Result?.SubscriberUser)
          );
          setIsUserLoggedIn(true);
          history.push("/");
        } else {
          Swal.fire({
            icon: "error",
            title: response?.data?.Response,
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        console.error("login ==> Error fetching data:", err);
        Swal.fire({
          icon: "error",
          title: "Login Failed, please try again!",
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
        });
      });
  };
  const sendMail = async () => {
    axios
      .post(baseURL + "loginweb", {
        email: formData.email,
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Email sent successfully",
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true,
        });
      });
  };
  function checkValuesPresent(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          return false;
        }
      }
    }
    return true;
  }
  const handleSubmit = () => {
    if (checkValuesPresent(formData) && !checkValuesPresent(inputError)) {
      ForgotPass ? sendMail() : fetchData();
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please complete the form.",
        showConfirmButton: true,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setInputError((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };
  const validateInput = (e) => {
    const { name, value } = e.target;
    if (!formData[name]) {
      setInputError((prevData) => ({
        ...prevData,
        [name]: `${[name]} is required`,
      }));
    } else {
      setInputError((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    }
  };
  function handleRememberMeChange(event) {
    setRememberMe(event.target.checked);
  }
  const renderTermModel = () => {
    return (
      <MyModal
        showModel={showTermsModel}
        setModel={setShowTermsModel}
        title="TERMS & CONDITIONS"
      >
        <p>
          This App gives you a license to be used on three different mobile
          devices and 1 Web-player on a computer.
          <br />
          You may, however, not distribute, sell, rent, lease, sub-lease, assign
          or otherwise transfer any of the sound in any format to a third party.
          <br />
          The Music is fully controlled by iChill Music.
        </p>
        <br />
        <p>
          For Android
          <br />
          14 Day no obligation FREE TRIAL.
          <br />
          The App can be downloaded free from the Play store.
          <br />
          Payment will be charged from your chosen payment method once payment
          details have been entered after the completion of the 40-day free
          trial.
          <br />
          Subscription automatically renews unless cancelled at least 24 hours
          before the end of the current period once it has been set up after the
          14-day Free trial.
          <br />
          Account will be charged for renewal within 24 hours prior to the end
          of the current period.
          <br />
          Subscriptions may be managed by the user and auto-renewal may be
          turned off by going to the user's Account Settings after purchase in
          the App.
          <br />
          &nbsp;
          <br />
          To cancel a subscription on Android devices.
          <br />
          Go to the menu of the App, then click on subscriptions and then select
          cancel subscription tap done.
        </p>
        <p>&nbsp;</p>
        <p>
          For Apple
          <br />
          14 Day no obligation FREE TRIAL.
          <br />
          The App can be downloaded free from the Play store.
          <br />
          Monthly Subscription £4.99.
          <br />
          Payment will be charged to iTunes Account at confirmation of purchase
          after the 14 day free trial ends.
          <br />
          Subscription automatically renews unless auto-renew is turned off at
          least 24-hours before the end of the current period
          <br />
          Account will be charged for renewal within 24-hours prior to the end
          of the current period.
          <br />
          Subscriptions may be managed by the user and auto-renewal may be
          turned off by going to the user's Account Settings after purchase.
          <br />
          No cancellation of the current subscription is allowed during active
          subscription period.
          <br />
          &nbsp;
          <br />
          How do I cancel a subscription on my iPhone?
          <br />
          Cancel subscription on iPhone, iPad or iPod
        </p>
        <p>
          <br />
          Access Settings in the home of your device;
          <br />
          Scroll down and tap iTunes Store and AppStore;
          <br />
          Tap your e-mail and Apple ID, View Apple ID and enter your password;
          <br />
          Now, tap Subscription - Manage and select Ichill music.
          <br />
          Turn off the auto-renewal option and tap Done.
        </p>
      </MyModal>
    );
  };
  const renderPolicyModel = () => {
    return (
      <MyModal
        showModel={showPolicyModel}
        setModel={setShowPolicyModel}
        title="PRIVACY POLICY"
      >
        <p>
          New Music Productions or iChill does not share any customer
          information with others outside of the normal course of business,
          except when specifically requested by the customer. To offer our
          customers products that will interest them. For example, we may
          contact you with news about special promotions or offers. However, we
          respect your preference to limit this communication. If you receive
          email or regular mail from us and you decide you would rather not
          receive such information in the future, simply notify us to remove
          your name from future communications.
        </p>
        <br />
        <p>
          14 Day FREE TRIAL, (No obligation to continue after 14 days).
          <br />
          Free to download the app
          <br />
          Monthly Subscription £4.99.
          <br />
          Payment will be charged to iTunes Account at confirmation of purchase
          <br />
          Subscription automatically renews unless auto-renew is turned off at
          least 24-hours before the end of the current period
          <br />
          Account will be charged for renewal within 24-hours prior to the end
          of the current period, and identify the cost of the renewal
          <br />
          Subscriptions may be managed by the user and auto-renewal may be
          turned off by going to the user's Account Settings after purchase
          <br />
          No cancellation of the current subscription is allowed during active
          subscription period
        </p>
        <br />
        <p>
          When you sign up for a Trial or any of our Paid Subscriptions or make
          other purchases through any of our services, your credit card
          information and other financial data that we need to process your
          payment are collected and stored by third party payment processors.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Data collection policy.</strong>
        </p>
        <p>- We collect email adress and type of phone the user has.</p>
        <br />
        <p>
          <strong>Purposes of data collection</strong>
        </p>
        <p>
          - We need to have an email address in order to create an account and
          to give the user access to the app.&nbsp;The type of phone is to
          monitor if certain phonetypes&nbsp;
        </p>
        <p>
          Become problematic with the app so updates might have to be done at
          our end to solve these technical issues.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>User data deletion policy</strong>
        </p>
        <p>
          - The user can contact us either through the app or email us from you
          published email address on our website{" "}
          <a
            href="https://upgrade.ichillmusic.com"
            style={{ color: "white" }}
            target="_parent"
          >
            www.upgrade.ichillmusic.com
          </a>
        </p>
      </MyModal>
    );
  };
  const renderPlanBar = () => {
    if (isNoPlan) {
      return (
        <>
          <div
            className="absolute w-full font-normal text-sm md:w-3/5 top-0 right-0 md:block hidden md:rounded-t-2xl md:rounded-l-none rounded-t-2xl"
            style={{
              background: "#FFCCCB",
              textAlign: "center",
              padding: "5px 0px",
              color: "#8B0000",
            }}
          >
            <span>
              <span className="font-semibold">ERROR!</span> Seems you are not
              subscribed user, only subscribed user can access the web player.
            </span>
          </div>
          <div
            className="absolute w-full font-normal text-sm md:w-3/5 right-0 md:hidden block"
            style={{
              background: "#FFCCCB",
              textAlign: "center",
              padding: "5px 0px",
              color: "#8B0000",
            }}
          >
            <span>
              <span className="font-semibold">ERROR!</span> Seems you are not
              subscribed user, only subscribed user can access the web player.
            </span>
          </div>
        </>
      );
    }
  };

  return (
    <div>
      {/* {showTermsModel && <Modal />} */}
      {renderTermModel()}
      {renderPolicyModel()}
      <div className="flex flex-col min-h-screen items-center justify-center w-screen bg-[url('assets/image/mainbg.jpg')] bg-cover bg-no-repeat bg-center">
        <div
          className="relative bg-ececec rounded-2xl flex flex-col md:flex-row w-11/12 text-black"
          style={{ maxWidth: "1500px" }}
        >
          <div className="bg-white flex items-center justify-center md:w-2/5 md:rounded-l-2xl md:rounded-r-none rounded-t-2xl">
            <img className="h-14 md:h-20 my-4" src={logo} alt="ichilli" />
          </div>
          {ForgotPass ? (
            <>
              <div className="flex md:w-2/5 flex-col justify-start w-100 md:m-auto px-8 py-12 font-semibold">
                <div className="text-xl md:text-3xl font-bold">
                  Forgot Password
                </div>
                <div className="sm:col-span-4">
                  <div className="mt-6">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      noValidate
                    >
                      <div className="flex mb-4 rounded-full shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          autoComplete="email"
                          className="block flex-1 rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm md:text-md font-bold sm:leading-6"
                          placeholder="Enter your registered email."
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <button
                        type="submit"
                        className="rounded-full bg-black w-full py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        SEND MAIL
                      </button>
                    </form>
                  </div>
                </div>
                <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
                  <p className="mx-4 mb-0 text-center font-semibold text-slate-500">
                    Or
                  </p>
                </div>
                <button
                  type="submit"
                  onClick={() => setForgotPass(false)}
                  className="rounded-full bg-black w-full py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  SIGN IN
                </button>
              </div>
            </>
          ) : (
            <div className="flex md:w-2/5 flex-col justify-start w-100 md:m-auto px-8 py-12 md:pt-12 pt-0 font-semibold">
              {renderPlanBar()}

              <div className="text-xl md:text-3xl font-bold md:pt-2 pt-12">
                Sign In to iChillmusic
              </div>
              <div className="text-xs md:text-sm opacity-80 font-bold color-[#666666]">
                Welcome back! Please login to your account.
              </div>
              <div className="sm:col-span-4">
                <div className="mt-6">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    noValidate
                  >
                    <Input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      onBlur={validateInput}
                      error={inputError.email}
                      placeholder="Enter your email"
                      className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm md:text-md font-bold sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
                    />
                    <Input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      onBlur={validateInput}
                      error={inputError.password}
                      placeholder="Enter your password"
                      className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm md:text-md font-bold sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
                    />
                    <div className="flex mb-4 justify-between">
                      <div className="text-sm">
                        <input
                          type="checkbox"
                          className="mr-2 cursor-pointer checked:bg-blue-500 indeterminate:bg-gray-300 valid:border-green-500 invalid:border-red-500"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                        Remember me
                      </div>
                      <div
                        className="cursor-pointer text-sm"
                        onClick={() => {
                          setForgotPass(true);
                          setFormData({});
                          setInputError({});
                        }}
                      >
                        Forgot password?
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="rounded-full bg-black w-full py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      SIGN IN
                    </button>
                  </form>
                </div>
              </div>
              <>
                <button className="custBtNNNN rounded-full bg-black w-full py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <AuthWithFbButton setIsUserLoggedIn={setIsUserLoggedIn} />
                </button>
              </>
              <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
                <p className="mx-4 mb-0 text-center font-semibold text-slate-500">
                  Or
                </p>
              </div>
              <Link to="/Register" className="">
                <button
                  type="submit"
                  className="rounded-full bg-black w-full py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Register
                </button>
              </Link>
            </div>
          )}
        </div>
        <div className="font-medium p-6">
          Copyright © 2023 iChillmusic . All rights reserved |{" "}
          <span
            className="cursor-pointer hover:underline"
            onClick={() => {
              setShowTermsModel(!showTermsModel);
            }}
          >
            Term of use
          </span>{" "}
          |{" "}
          <span
            className="cursor-pointer hover:underline"
            onClick={() => setShowPolicyModel(!showPolicyModel)}
          >
            Privacy Policy
          </span>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
