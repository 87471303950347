import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "components/Icons";
import { useDispatch } from "react-redux";
import { setPlayList } from "stores/rootReducer";

export default function Album({ item }) {
  const dispatch = useDispatch();
  const handleAlbumClick = () => {
    dispatch(setPlayList(item));
    localStorage.setItem("playlist", JSON.stringify(item));
  };

  return (
    <NavLink
      key={item.UserPlayListId}
      to={`/PlayList/${
        item.PlayListName || item.CategoryName || item.albumTitle
      }`}
      onClick={() => handleAlbumClick()}
      className={
        "bg-card rounded p-4 transition-all hover:bg-card hover:scale-105 group flex flex-col m-2 md:m-4 albumCardNavLink"
      }
    >
      <div className="albumImageWrapper">
        <img className="rounded" src={item.CategoryImage || item.AlbumImage} />
        <div className="w-12 h-12 bg-[#26C281] absolute right-0 bottom-0 top-0 left-0 m-auto transition-opacity rounded-full flex items-center shadow-2xl justify-center opacity-0 group-hover:opacity-100 group-active:opacity-100">
          <Icon name="play" />
        </div>
      </div>
      <div className="albumTextWrapper font-semibold text-white text-[16px] mt-4">
        {item.PlayListName || item.albumTitle || item.CategoryName}
      </div>
      {/* <br />
      <span className="mt-1 text-[14px] text-link">{item.PlayListName || item.CategoryName}</span> */}
    </NavLink>
  );
}
