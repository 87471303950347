import React from "react";
import { Menu } from "@headlessui/react";
import { Icon } from "components/Icons";
import { useHistory } from "react-router-dom";
import logo from "assets/image/ichilli_croped.png";
import { getUserData } from "Utils";
import { useState } from "react";
import { useEffect } from "react";

export default function User({ setIsUserLoggedIn }) {
  const Username = getUserData();
  const person = {
    name: Username?.FirstName + " " + Username?.LastName,
    avatar: logo,
  };
  const history = useHistory();
  // const [Name, setName] = useState(
  //   Username?.FirstName + " " + Username?.LastName
  // );

  // useEffect(() => {
  //   // const dataFromLocalStorage = localStorage.getItem("USER");
  //   // if (dataFromLocalStorage) {
  //   //   setName(Username?.FirstName + " " + Username?.LastName);
  //   // }

  //   const handleStorageChange = (e) => {
  //     if (e.key === "USER") {
  //       setName(Username?.FirstName + " " + Username?.LastName);
  //     }
  //   };
  //   window.addEventListener("storage", handleStorageChange);
  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);

  return (
    <Menu as="nav" className={"relative"}>
      {({ open }) => (
        <>
          <Menu.Button
            className={`flex items-center gap-x-[6px] rounded-[23px] p-1 ${
              open ? "bg-active" : "bg-brenk"
            }`}
          >
            <img
              className="w-[28px] h-[28px] rounded-full"
              src={person.avatar}
            />
            <span className="text-sm font-semibold text-[#26C281] hidden">
              {/* <span className="text-sm font-semibold text-[#26C281] hidden md:block"> */}
              {person.name}
            </span>
            <span className={open === true && "rotate-180"}>
              <Icon name="dir" />
            </span>
          </Menu.Button>
          <Menu.Items className="absolute p-1 top-full right-0 w-48 bg-active rounded translate-y-2 z-10">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={`h-10 flex px-2 text-sm font-semibold justify-between text-white opacity-80 items-center cursor-pointer ${
                    active && "bg-menubg"
                  }`}
                  onClick={() => {
                    history.push("/MyProfile");
                  }}
                >
                  My Profile
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={`h-10 flex px-2 text-sm font-semibold justify-between text-white opacity-80 items-center cursor-pointer ${
                    active && "bg-menubg"
                  }`}
                  onClick={() => {
                    localStorage.removeItem("USERID");
                    sessionStorage.removeItem("USERID");
                    setIsUserLoggedIn(false);
                    history.push("/SignIn");
                  }}
                >
                  Sign out
                  <Icon name="external" />
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}
