import React from "react";
import { useHistory } from "react-router-dom";
import { getUserData } from "Utils";

const Payment = () => {
  const history = useHistory();
  const userData = getUserData();
  const iframeUrl = `https://upgrade.ichillmusic.com/paypal/creditCardPayment?planId=1&userId=${
    userData?.UserId || "0"
  }`;

  // const webView = fetch(iframeUrl, {
  //   mode: "no-cors", // Specify 'no-cors' mode
  // }).then((response) => {
  //   console.log(response, "webView");
  // if (!response.ok) {
  //   throw new Error("Network response was not ok");
  // }
  // return response.text();
  // });
  // .catch((html) => console.log(html, "webView"))
  // .catch((error) => {
  //   console.error("Error fetching data webView:", error);
  // });

  return (
    <div className="payment">
      <div>
        <h3 className="text-3xl text-white font-bold tracking-tight mb-6">
          Payment
        </h3>
      </div>

      <iframe
        src={iframeUrl}
        title="iChillMusic Payment"
        width="100%"
        height="900px" // Adjust the height as needed
        frameBorder="0"
        allowFullScreen
      ></iframe>
      <div>
        <button
          onClick={() => history.goBack()}
          className="rounded-full bg-white w-full mt-8 mb-20 py-4 px-6 text-sm font-semibold text-black shadow-sm hover:bg-[#cdcdcd]"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Payment;
