import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PlayBox from "components/PlayBox";
import ArtistBox from "components/ArtistBox";
import { Icon } from "components/Icons";
import { useDispatch } from "react-redux";
import { setCurrent } from "stores/player";
import { useSelector } from "react-redux";
// import options from "assets/image/options-vertical.svg";
// import options from "public/ichillwebreactjs/assets/image/options-vertical.svg";
import playic from "assets/image/playic.png";
import pauseic from "assets/image/pauseic.png";
import info from "assets/image/info.png";
import MyModal from "./Modal";
import Input from "./Input";
import axios from "axios";
import { baseURL } from "Utils";
import AddToPlaylistModal from "./AddToPlaylistModal/AddToPlaylistModal";
import AudioVisualizer from "./AudioVisualizer";

export default function PlaylistItemBar({
  item,
  updatePlaylist,
  IsMultiSelection,
  handleSongCheckBoxChange,
  SelectedSongList,
  playList,
  reFetchSongs,
}) {
  const { current, playing } = useSelector((state) => state.player);
  const { dashboardList } = useSelector((state) => state.rootReducer);
  const [showAddToPlaylistModel, setShowAddToPlaylistModel] = useState(false);
  const dispatch = useDispatch();

  const isSameSongPLaying = () => {
    if (current?.UserSongListId) {
      if (current?.UserSongListId == item.UserSongListId) {
        return true;
      } else {
        return false;
      }
    } else {
      if (current?.SongId) {
        if (current?.SongId == item.SongId) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  const updateCurrent = () => {
    if (isSameSongPLaying()) {
      // dispatch(setPlaying(false));
      document.querySelector(".playBtnMain")?.click();
    } else {
      dispatch(setCurrent(item));
      updatePlaylist();
      // dispatch(setPlaying(true));
    }
  };
  return (
    <div
      className={`relative flex group w-full flex-row items-center hover:bg-card rounded-full border-gray-400 border-b border-opacity-5 text-sm text-left font-bold text-link hover:text-black mb-2 transition-all ${
        isSameSongPLaying() ? "bg-playing" : "bg-card"
      }`}
    >
      {/* {renderAddToPlaylistModel()}
      {renderConfirmationModel1()} */}
      <AddToPlaylistModal
        showModel={showAddToPlaylistModel}
        setModel={setShowAddToPlaylistModel}
        item={item}
        playList={playList}
        reFetchSongs={reFetchSongs}
      />
      <div
        className="flex items-center w-full flex-row cursor-pointer"
        onClick={updateCurrent}
      >
        <div className="flex-none pl-2 py-2 relative">
          {IsMultiSelection ? (
            <input
              className=""
              type="checkbox"
              name="checkbox"
              id={item.SongId}
              checked={
                SelectedSongList.includes(item.SongId.toString()) || false
              }
              onChange={handleSongCheckBoxChange}
              onClick={(e) => e.stopPropagation()}
            />
          ) : isSameSongPLaying() && playing ? (
            <img
              className="group-hover:scale-105 w-8 h-8 rounded-full transition-all"
              src={pauseic}
              alt={pauseic}
            />
          ) : (
            <img
              className="group-hover:scale-105 w-8 h-8 rounded-full transition-all"
              src={playic}
              alt={playic}
            />
          )}
          {/* <Icon name="play" /> */}
        </div>
        <div className="group-hover:scale-105 px-6 py-2">{item.SongTitle}</div>
        {isSameSongPLaying() && playing && (
          <div className="md:absolute left-[49%] text-center mx-auto">
            <AudioVisualizer />
          </div>
        )}
      </div>

      <div
        className="flex-none pr-6 pl-2 py-2 text-right cursor-pointer"
        onClick={() => setShowAddToPlaylistModel(true)}
      >
        {/* <img
          height={20}
          width={20}
          src={"/public/ichillwebreactjs/assets/image/options-vertical.svg"}
          alt="sad"
        /> */}
        <Icon name="optionVertical" />
      </div>
    </div>
  );
}
