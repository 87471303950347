import { Icon } from "components/Icons";
import { NavLink } from "react-router-dom";
import React from "react";

export default function Menu({ setShowSidebar }) {
  const menuItems = [
    {
      name: "Home",
      to: "/",
      icon: "home",
      activeIcon: "acthome",
    },
    {
      name: "Best of iChill",
      to: "/BestOfIchill",
      icon: "BestOf",
      activeIcon: "BestOf",
    },
    {
      name: "New Releases & Categories",
      to: "/NewReleases",
      icon: "lib",
      activeIcon: "actcollection",
    },
    {
      name: "Albums",
      to: "/Albums",
      icon: "albumsIcon",
      activeIcon: "albumsIcon",
    },
    // { name: "Search", to: "/search", icon: "search", activeIcon: "actsearch" },
    // {
    //   name: "your library",
    //   to: "/collection",
    //   icon: "lib",
    //   activeIcon: "actcollection",
    // },
  ];
  return (
    <div id="tummenu">
      <nav className="px-2">
        <ul className="flex flex-col">
          {menuItems.map((menuItem) => (
            <li>
              <NavLink
                activeClassName="activePageLink"
                to={menuItem.to}
                onClick={() => setShowSidebar(false)}
                exact
                className="h-12 flex items-center text-sm font-bold text-link hover:text-white px-4 py-2"
              >
                {window.location.pathname === menuItem.to ? (
                  <Icon name={menuItem.activeIcon} />
                ) : (
                  <Icon name={menuItem.icon} />
                )}
                <span className="ml-4">{menuItem.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
