import { configureStore } from "@reduxjs/toolkit";
import playerReducer from "stores/player";
import rootReducer from "stores/rootReducer";

export default configureStore({
  reducer: {
    player: playerReducer,
    rootReducer: rootReducer,
  },
});
