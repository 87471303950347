import React from "react";
import { Icon } from "components/Icons";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { setPlayList, setSearchTerm } from "stores/rootReducer";
import { useDispatch, useSelector } from "react-redux";

export default function Search() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.player);

  const [CurrentTerm, setCurrentTerm] = useState();

  const goToPrevPlaylist = () => {
    if (localStorage.getItem("prevPlaylistPath") != window.location.pathname) {
      localStorage.setItem(
        "playlist",
        localStorage.getItem("prevPlaylistWhenPlayed")
      );
      dispatch(
        setPlayList(JSON.parse(localStorage.getItem("prevPlaylistWhenPlayed")))
      );
      history.push(localStorage.getItem("prevPlaylistPath"));
      // history.push(`/PlayList/${currentPlaylist.albumTitle}`);
    }
  };

  return (
    <div className="flex mr-auto ml-4 relative w-96">
      <label
        htmlFor="search-input"
        className="text-black w-12 h-10 flex items-center justify-center absolute top-0 left-0"
      >
        <Icon size={24} name="topsearch" />
      </label>
      <input
        autoFocus={false}
        type="text"
        id="search-input"
        className="h-10 pl-12 outline-none text-black font-medium bg-white rounded-3xl text-sm placeholder-black/50 w-full max-w-[22.75rem]"
        placeholder="Search Albums..."
        onFocus={() => {
          history.push("/Search");
        }}
        value={CurrentTerm}
        onChange={(e) => {
          setCurrentTerm(e.target.value);
          dispatch(setSearchTerm(e.target.value));
        }}
      />
      {current && (
        <div className="pl-2 cursor-pointer" onClick={() => goToPrevPlaylist()}>
          <Icon name="musicPuls" />
        </div>
      )}
    </div>
  );
}
