import React from "react";
import { useState, useEffect } from "react";
import { Icon } from "components/Icons";
import { useAudio } from "react-use";
import { secondsToTime } from "Utils";
import MyRange from "components/MyRange";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  setControls,
  setCurrent,
  setCurrentRepeatState,
  setPlaying,
} from "stores/player";
import { useDispatch } from "react-redux";
import logo from "assets/image/ichilli_croped.png";
import { setPlayList, setShuffle } from "stores/rootReducer";
import { useHistory } from "react-router-dom";

export default function Player() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { current, currentPlaylist } = useSelector((state) => state.player);
  const { isShuffle } = useSelector((state) => state.rootReducer);
  const [repeatState, setRepeatState] = useState(1);

  const [audio, state, controls, ref] = useAudio({
    src: current?.OriginalFile,
    autoPlay: true,
  });

  useEffect(() => {
    dispatch(setPlaying(state?.playing));
  }, [state?.playing]);
  useEffect(() => {
    controls.play();
    !current?.albumTitle &&
      localStorage.setItem(
        "prevIfNoAlbumName",

        JSON.parse(localStorage.getItem("playlist"))?.albumTitle ||
          JSON.parse(localStorage.getItem("playlist"))?.CategoryName ||
          null
      );
    !current.AlbumImage &&
      localStorage.setItem(
        "prevIfNoAlbumImage",
        JSON.parse(localStorage.getItem("playlist"))?.AlbumImage || null
      );

    localStorage.setItem(
      "prevPlaylistWhenPlayed",
      localStorage.getItem("playlist") || null
    );
    localStorage.setItem("prevPlaylistPath", window.location.pathname);
  }, [current]);
  useEffect(() => {
    setRepeatState(1);
  }, [currentPlaylist]);

  useEffect(() => {
    controls.volume(0.6);
    dispatch(setControls(controls));
  }, []);
  useEffect(() => {
    if (
      secondsToTime(state?.time) != "00:00" &&
      secondsToTime(state?.time) == secondsToTime(state?.duration)
    ) {
      if (repeatState == 3) {
        controls.seek(0);
        controls.play();
      } else {
        if (currentPlaylist?.length > 1) {
          const currentIndex = currentPlaylist.findIndex(
            (item) => item.SongId == current?.SongId
          );

          let nextItem;

          if (
            currentIndex !== -1 &&
            currentIndex < currentPlaylist.length - 1
          ) {
            // If the SongId is found and it's not the last item in the array
            nextItem = currentPlaylist[currentIndex + 1];
          } else {
            // If the SongId is not found or it's the last item in the array, return the first item
            if (repeatState == 2) {
              // currentRepeatState is repeatPlaylist
              nextItem = currentPlaylist[0];
            }
          }

          if (nextItem) {
            dispatch(setCurrent(nextItem));
          }
        }
      }
    }
  }, [state?.time]);

  const volumeIcon = useMemo(() => {
    if (state.volume === 0 || state.muted) return "mute";
    if (state.volume > 0 && state.volume < 0.33) return "low";
    if (state.volume >= 0.33 && state.volume < 0.66) return "mid";
    return "full";
  }, [state.volume, state.muted]);

  const handleNextBtnClick = () => {
    // console.log("currentPlaylist", currentPlaylist);
    if (currentPlaylist?.length > 0) {
      if (currentPlaylist?.length == 1) {
        controls.seek(0);
      }
      const currentIndex = currentPlaylist.findIndex(
        (item) =>
          item.SongId == current?.SongId &&
          item.UserSongListId == current?.UserSongListId
      );

      let nextItem;

      if (currentIndex !== -1 && currentIndex < currentPlaylist.length - 1) {
        // If the SongId is found and it's not the last item in the array
        nextItem = currentPlaylist[currentIndex + 1];
      } else {
        // If the SongId is not found or it's the last item in the array, return the first item
        nextItem = currentPlaylist[0];
      }

      if (nextItem) {
        dispatch(setCurrent(nextItem));
      }
    } else {
      controls.seek(0);
    }
  };
  const handlePrevBtnClick = () => {
    if (currentPlaylist?.length > 0) {
      if (currentPlaylist?.length == 1) {
        controls.seek(0);
      }
      const currentIndex = currentPlaylist.findIndex(
        (item) =>
          item.SongId == current?.SongId &&
          item.UserSongListId == current?.UserSongListId
      );

      let prevItem;

      if (currentIndex !== -1 && currentIndex > 0) {
        // If the SongId is found and it's not the last item in the array
        prevItem = currentPlaylist[currentIndex - 1];
      } else {
        // If the SongId is not found or it's the last item in the array, return the first item
        prevItem = currentPlaylist[currentPlaylist.length - 1];
      }

      if (prevItem) {
        dispatch(setCurrent(prevItem));
      }
    } else {
      controls.seek(0);
    }
  };

  const toggleState = () => {
    setRepeatState((prevState) => {
      // Toggle between 1, 2, and 3
      if (prevState === 1) return 2;
      if (prevState === 2) return 3;
      if (prevState === 3) return 1;
    });
  };
  // console.log("current", current);

  const goToPrevPlaylist = () => {
    if (localStorage.getItem("prevPlaylistPath") != window.location.pathname) {
      localStorage.setItem(
        "playlist",
        localStorage.getItem("prevPlaylistWhenPlayed")
      );
      dispatch(
        setPlayList(JSON.parse(localStorage.getItem("prevPlaylistWhenPlayed")))
      );
      history.push(localStorage.getItem("prevPlaylistPath"));
      // history.push(`/PlayList/${currentPlaylist.albumTitle}`);
    }
  };

  return (
    <div className="relative flex flex-col md:flex-row justify-between items-center px-4">
      <div className="absolute top-[-24px] md:top-[-9px] md:relative w-full md:min-w-[11.25rem] md:w-[30%] hidden md:flex">
        {current && (
          <div className="flex items-center">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => goToPrevPlaylist()}
            >
              <img
                className="w-14 h-14 mr-3 rounded-lg hidden md:flex"
                src={
                  current.AlbumImage ||
                  localStorage.getItem("prevIfNoAlbumImage") ||
                  logo
                }
              />
              <div>
                <h4 className="text-lg font-bold text-white">
                  {current.SongTitle}
                </h4>
                <h6 className="text-sm text-tartist">
                  {current?.albumTitle
                    ? current?.albumTitle
                    : localStorage.getItem("prevIfNoAlbumName") || ""}
                </h6>
              </div>
            </div>
            {/* <button className="w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100 ml-4">
              <Icon size={16} name="wishlist" />
            </button>
            <button className="w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100 ">
              <Icon size={16} name="screen" />
            </button> */}
          </div>
        )}
      </div>
      <div className="flex flex-col items-center w-full md:max-w-[45.125rem] md:w-[40%]">
        <div className="flex ">
          <button
            className={`w-8 h-8 flex items-center justify-center text-opacity-70 hover:text-opacity-100 ${
              isShuffle ? "text-[#26C281]" : "text-white"
            }`}
            onClick={() => dispatch(setShuffle(!isShuffle))}
          >
            <Icon name="shuffle" />
          </button>
          <button
            className="mx-3 w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100"
            onClick={() => handlePrevBtnClick()}
          >
            <Icon name="playerprev" />
          </button>
          <button
            onClick={controls[state?.playing ? "pause" : "play"]}
            className="playBtnMain flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100"
          >
            <div className="bg-[#26C281] rounded-full p-2 hover:scale-105">
              <Icon name={state?.playing ? "ppause" : "pplay"} />
            </div>
          </button>
          <button
            className="mx-3 w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100"
            onClick={() => handleNextBtnClick()}
          >
            <Icon name="playernext" />
          </button>
          <button
            className="w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100"
            onClick={toggleState}
          >
            <Icon
              name={
                repeatState === 1
                  ? "repeat"
                  : repeatState === 2
                  ? "repeatList"
                  : "repeatOne"
              }
            />
            {/* <Icon name="repeat" /> */}
          </button>
        </div>

        <div className="w-full flex items-center gap-x-2">
          {audio}
          <div className="text-[11px] text-gribottom">
            {secondsToTime(state?.time)}
          </div>
          <MyRange
            step={0.1}
            min={0}
            max={state?.duration || 1}
            value={state?.time}
            onChange={(value) => controls.seek(value)}
          />

          <div className="text-[11px] text-gribottom">
            {secondsToTime(state?.duration)}
          </div>
        </div>
      </div>
      <div className="absolute scale-90 md:scale-100 top-[-30px] md:top-[-9px] right-[-15px] md:w-[30%] md:relative flex md:min-w-[11.25rem] items-center justify-end">
        {/* <button className="w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100">
          <Icon name="mic" />
        </button>
        <button className="w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100">
          <Icon name="addlist" />
        </button>
        <button className="w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100">
          <Icon name="device" />
        </button> */}
        <button
          onClick={controls[state?.muted ? "unmute" : "mute"]}
          className="w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100"
        >
          <Icon name={volumeIcon} />
        </button>
        <div className="w-[5.813rem]">
          <MyRange
            step={0.01}
            min={0}
            max={1}
            value={state.muted ? 0 : state?.volume}
            onChange={(value) => {
              controls.unmute();
              controls.volume(value);
            }}
          />
        </div>
        {/* <button className="w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100">
          <Icon name="fullscreen" />
        </button> */}
      </div>
    </div>
  );
}
