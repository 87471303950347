import Navigation from "./Navbar/Navigation";
import User from "./Navbar/User";
import { Link, useRouteMatch } from "react-router-dom";
import TopSearch from "./Navbar/TopSearch";
import logo from "assets/image/ichilli_logo.png";
import { getUserData, getUserSubscriptionData } from "Utils";

export default function Navbar({
  showSidebar,
  setShowSidebar,
  setIsUserLoggedIn,
}) {
  function daysUntilTargetDate(targetDate) {
    // Parse the target date string (assuming the format is "DD/MM/YYYY")
    const [day, month, year] = targetDate.split("/").map(Number);
    const target = new Date(year, month - 1, day); // Month is 0-indexed

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = target - currentDate;

    // Convert milliseconds to days
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysRemaining;
  }
  const renderPlanBar = () => {
    const UserSubscriptionDetails = getUserSubscriptionData();
    const userData = getUserData();
    // console.log("UserSubscriptionDetails", userData?.SubscriberUser, UserSubscriptionDetails)
    if(userData?.SubscriberUser == "1"){
      return null;
    }
    const remainingDays =
      UserSubscriptionDetails?.EndDate &&
      daysUntilTargetDate(UserSubscriptionDetails?.EndDate);
    // console.log("remainingDays", remainingDays);
    if (remainingDays && remainingDays < 11) {
      return (
        <>
          <div
            className="w-full top-full text-sm font-semibold"
            style={{
              background: "#FFCCCB",
              textAlign: "center",
              padding: "5px 0px",
              color: "#8B0000",
            }}
          >
            <span className="">
              {remainingDays < 1
                ? "Your plan will expire today."
                : `Your plan will expire in ${remainingDays} days.`}{" "}
              <Link
                // onClick={() =>
                //   window.open(
                //     "https://upgrade.ichillmusic.com/paypal/creditCardPayment?planId=1&userId=0",
                //     "_blank"
                //   )
                // }
                to={"/Payment"}
                style={{
                  textDecoration: "underline",
                }}
              >
                Click here
              </Link>{" "}
              for upgrading your plan.
            </span>
          </div>
        </>
      );
    }
  };
  return (
    <div className="relative flex flex-col w-full">
      <nav
        className={`h-[4rem] w-full flex items-center justify-between px-2 md:px-8 ${""}`}
      >
        <div className="w-10 md:hidden">
          <svg
            onClick={() => setShowSidebar(!showSidebar)}
            className="z-30 flex items-center cursor-pointer"
            fill="#FFFFFF"
            viewBox="0 0 100 80"
            width="30"
            height="40"
          >
            <rect width="100" height="10"></rect>
            <rect y="30" width="100" height="10"></rect>
            <rect y="60" width="100" height="10"></rect>
          </svg>
        </div>
        {/* <Navigation /> */}
        <TopSearch />
        <User setIsUserLoggedIn={setIsUserLoggedIn} />
      </nav>
      {renderPlanBar()}
    </div>
  );
}
