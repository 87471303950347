import React from "react";
import Album from "components/Album";
import { useSelector } from "react-redux";

export default function BestOfIchill() {
  const { dashboardList } = useSelector((state) => state.rootReducer);
  const targetTitle = "Best of iChill";
  if (dashboardList.length === 0) {
    return <></>;
  }
  return (
    <div>
      <div>
        <h3 className="text-3xl text-white font-bold tracking-tight mb-6">
          Best of iChill
        </h3>
      </div>
      <div className="albumsPage mb-8">
        {dashboardList.map((list) => (
          <>
            {list.title == targetTitle &&
              list.records.map((item) => (
                <Album item={item} key={item.UserPlayListId} />
              ))}
          </>
        ))}
      </div>
    </div>
  );
}
