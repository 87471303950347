import Input from "components/Input";
import MyModal from "components/Modal";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import info from "assets/image/info.png";
import deleteIcon from "assets/image/delete.svg";
import axios from "axios";
import { baseURL } from "Utils";
import { setDashboard, setLoading, setPlayList } from "stores/rootReducer";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const AddToPlaylistModal = ({ showModel, setModel, item, isMultiSong, setIsMultiSelection, playList, reFetchSongs }) => {
  // console.log("props, item", item);
  const dispatch = useDispatch();
  const history = useHistory();
  const { dashboardList } = useSelector((state) => state.rootReducer);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionId, setSelectedOptionId] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalVisible2, setModalVisible2] = useState(false);
  const [isModalVisible3, setModalVisible3] = useState(false);
  const [inputError, setInputError] = useState();
  const [newPlayListName, setNewPlayListName] = useState();
  const userId = localStorage.getItem("USERID") || sessionStorage.getItem("USERID");
  const currentPlaylist = playList || JSON.parse(localStorage.getItem("playlist"));
  const ShouldShowDeleteBtn =
    dashboardList &&
    dashboardList
      ?.find((playlist) => {
        return playlist.title === "My playlists";
      })
      ?.records?.some((item) => item?.PlayListName == currentPlaylist?.PlayListName);

  const matchedPlaylist =
    dashboardList &&
    dashboardList?.find((playlist) => {
      return playlist.title === "My playlists";
    });

  const SetUserDashboardFunc = () => {
    axios
      .post(baseURL + "api/userDashboard", {
        user_id: localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
      })
      .then((response) => {
        dispatch(setDashboard(response?.data?.result));
      })
      .catch((err) => console.error("error:", err));
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedOptionId(event.target.id);
  };
  const handleAddToPlayListClick = () => {
    if (isMultiSong) {
      // for Multiple add to playlist
      var songIds = item.map((k) => k).join(",");

      axios
        .post(baseURL + "api/addMultipleSongsToPlayList", {
          user_id: localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
          playlistname: selectedOption,
          songid: songIds,
          pid: selectedOptionId, // playlistId
          playlistflag: 1, //1=add playlist,add song,2=remove playlist,remove song
          isCheck: true, // true for firstTime
          // albumid: 1,
          isAgain: 0, // 0 for first time to do not duplicate item in playlist
        })
        .then((response) => {
          // console.log("Playlist multi add result: ", response);
          if (response?.data?.status == "success") {
            setSelectedOption("");
            setSelectedOptionId("");
            setModel(false);
            setIsMultiSelection(false);
            Swal.fire({
              icon: "success",
              title: "songs added successfully",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
          } else if (
            response?.data?.status == "fail" &&
            response?.data?.Result == `Some of these are already in your '${selectedOption}' playlist.`
          ) {
            setModel(false);
            setTimeout(() => {
              setModalVisible3(true);
            }, 500);
          } else if (response?.data?.status == "fail" && response?.data?.Result == `These are already in your '${selectedOption}' playlist.`) {
            setModel(false);
            setTimeout(() => {
              setModalVisible2(true);
            }, 500);
          }
        })
        .catch((err) => console.error("error:", err));
    } else {
      axios
        .post(baseURL + "api/addUpdatePlayList", {
          user_id: localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
          playlistname: selectedOption,
          songid: item.SongId,
          pid: selectedOptionId, // playlistId
          playlistflag: 1, //1=add playlist,add song,2=remove playlist,remove song
          // albumid: 1,
          isAgain: 0, // 0 for first time to do not duplicate item in playlist
        })
        .then((response) => {
          if (response?.data?.status == "success") {
            setSelectedOption("");
            setSelectedOptionId("");
            setModel(false);
            Swal.fire({
              icon: "success",
              title: "songs added successfully",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
          } else if (response?.data?.status == "fail" && response?.data?.message == "Song already added.") {
            setModel(false);
            setTimeout(() => {
              setModalVisible(true);
            }, 500);
          }
        })
        .catch((err) => console.error("error:", err));
    }
  };
  const renderConfirmationModel1 = () => {
    // single product duplicate ditacted
    return (
      <MyModal showModel={isModalVisible} setModel={() => handleNoClick(1)} title="Please Confirm">
        <p className=" pt-2">Song already added, do you want to add this song again?</p>
        <div className="w-full pt-2">
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => handleNoClick(1)}
          >
            No
          </button>
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleYesClick}
          >
            Yes
          </button>
        </div>
      </MyModal>
    );
  };
  const renderConfirmationModel2 = () => {
    // multi product duplicate ditacted for all same
    return (
      <MyModal showModel={isModalVisible2} setModel={() => handleNoClick(2)} title="Please Confirm">
        <p className=" pt-2">These are already in your '{selectedOption}' playlist.</p>
        <div className="w-full pt-2">
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => handleNoClick(2)}
          >
            Don't add
          </button>
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleAddAnywayClick}
          >
            Add anyway
          </button>
        </div>
      </MyModal>
    );
  };
  const renderConfirmationModel3 = () => {
    // multi product duplicate ditacted for Some same
    return (
      <MyModal showModel={isModalVisible3} setModel={() => handleNoClick(3)} title="Please Confirm">
        <p className=" pt-2">Some of these are already in your '{selectedOption}' playlist.</p>
        <div className="w-full pt-2">
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleAddAnywayClick}
          >
            Add all
          </button>
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleAddNewClick}
          >
            Add new ones
          </button>
        </div>
      </MyModal>
    );
  };
  const handleYesClick = () => {
    axios
      .post(baseURL + "api/addUpdatePlayList", {
        user_id: localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
        playlistname: selectedOption,
        songid: item.SongId,
        pid: selectedOptionId,
        playlistflag: 1,
        // albumid: 1,
        isAgain: 1,
      })
      .then((response) => {
        if (response?.data?.status == "success") {
          setSelectedOption("");
          setModalVisible(false);
          Swal.fire({
            icon: "success",
            title: "songs added successfully",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        } else {
          setSelectedOption("");
          setModalVisible(false);
          Swal.fire({
            icon: "error",
            title: "failed.",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => console.error("error:", err));
  };
  const handleNoClick = (type) => {
    if (type == 1) {
      setModalVisible(false);
    } else if (type == 2) {
      setModalVisible2(false);
    } else if (type == 3) {
      setModalVisible3(false);
    }
    setSelectedOption("");
    setSelectedOptionId("");
    setIsMultiSelection(false);
  };
  const handleAddAnywayClick = () => {
    var songIds = item.map((k) => k).join(",");
    axios
      .post(baseURL + "api/addMultipleSongsToPlayList", {
        user_id: localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
        playlistname: selectedOption,
        songid: songIds,
        pid: selectedOptionId, // playlistId
        playlistflag: 1, //1=add playlist,add song,2=remove playlist,remove song
        isCheck: false, // true for firstTime
        duplicateType: 2, // 1 for new add, 2 for all add
        // albumid: 1,
        isAgain: 1, // 0 for first time to do not duplicate item in playlist
      })
      .then((response) => {
        if (response?.data?.status == "success") {
          setSelectedOption("");
          setModalVisible2(false);
          setModalVisible3(false);
          setIsMultiSelection(false);
          Swal.fire({
            icon: "success",
            title: "songs added successfully",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        } else {
          setSelectedOption("");
          setModalVisible2(false);
          setModalVisible3(false);
          setIsMultiSelection(false);
          Swal.fire({
            icon: "error",
            title: "failed.",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => console.error("error:", err));
  };
  const handleAddNewClick = () => {
    var songIds = item.map((k) => k).join(",");
    axios
      .post(baseURL + "api/addMultipleSongsToPlayList", {
        user_id: localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
        playlistname: selectedOption,
        songid: songIds,
        pid: selectedOptionId, // playlistId
        playlistflag: 1, //1=add playlist,add song,2=remove playlist,remove song
        isCheck: false, // true for firstTime
        duplicateType: 1, // 1 for new add, 2 for all add
        // albumid: 1,
        isAgain: 1, // 0 for first time to do not duplicate item in playlist, 1 for new add, 2 for all add
      })
      .then((response) => {
        if (response?.data?.status == "success") {
          setSelectedOption("");
          setModalVisible3(false);
          setIsMultiSelection(false);
          Swal.fire({
            icon: "success",
            title: "songs added successfully",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        } else {
          setSelectedOption("");
          setModalVisible3(false);
          setIsMultiSelection(false);
          Swal.fire({
            icon: "error",
            title: "failed.",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => console.error("error:", err));
  };
  const handleCreatePlaylist = () => {
    if (newPlayListName) {
      if (isMultiSong) {
        var allSongIds = item.map((k) => k).join(",");
        axios
          .post(baseURL + "api/addMultipleSongsToPlayList", {
            user_id: localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
            playlistname: newPlayListName,
            songid: allSongIds,
            pid: 0, // playlistId
            playlistflag: 1, //1=add playlist,add song,2=remove playlist,remove song
            isCheck: false,
            isAgain: 1, // 0 for first time to do not duplicate item in playlist
          })
          .then((response) => {
            if (response?.data?.status == "success") {
              setSelectedOption("");
              setSelectedOptionId("");
              setInputError();
              setNewPlayListName();
              setModel(false);
              SetUserDashboardFunc();
              Swal.fire({
                icon: "success",
                title: "song added to new playlist",
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true,
              });
            } else if (response?.data?.status == "fail") {
              setSelectedOption("");
              setSelectedOptionId("");
              setInputError();
              setNewPlayListName();
              setModel(false);
              Swal.fire({
                icon: "error",
                title: response?.data?.message,
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true,
              });
            }
          })
          .catch((err) => console.error("error:", err));
      } else {
        axios
          .post(baseURL + "api/addUpdatePlayList", {
            userid: localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
            playlistname: newPlayListName,
            songid: item.SongId,
            pid: 0, // playlistId
            playlistflag: 1, //1=add playlist,add song,2=remove playlist,remove song
            albumid: item?.AlbumId || 0,
            // isAgain: 0, // 0 for first time to do not duplicate item in playlist
          })
          .then((response) => {
            if (response?.data?.status == "success") {
              setSelectedOption("");
              setSelectedOptionId("");
              setInputError();
              setNewPlayListName();
              setModel(false);
              SetUserDashboardFunc();
              Swal.fire({
                icon: "success",
                title: "song added to new playlist",
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true,
              });
            } else if (response?.data?.status == "fail") {
              setSelectedOption("");
              setSelectedOptionId("");
              setInputError();
              setNewPlayListName();
              setModel(false);
              Swal.fire({
                icon: "error",
                title: response?.data?.message,
                showConfirmButton: false,
                timer: 2500,
                timerProgressBar: true,
              });
            }
          })
          .catch((err) => console.error("error:", err));
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "please enter playlist name",
        showConfirmButton: true,
      });
    }
  };
  const handleRemoveFromPlaylist = () => {
    axios
      .post(baseURL + "api/addUpdatePlayList", {
        user_id: localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
        // playlistname: selectedOption,
        songid: item.SongId,
        pid: currentPlaylist.PlayListId, // playlistId
        playlistflag: 2, //1=add playlist,add song,2=remove playlist,remove song
      })
      .then((response) => {
        if (response?.data?.status == "success") {
          reFetchSongs();
          setModel(false);
          Swal.fire({
            icon: "success",
            title: "songs deleted from playlist.",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        } else {
          setModel(false);
          Swal.fire({
            icon: "error",
            title: "delete failed.",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => console.error("error:", err));
  };
  const handleGoToAlbumClick = () => {
    const parentAlbumId = item?.AlbumId;
    if (parentAlbumId) {
      const matchedParentList = dashboardList.find(
        (list) => list.title == "Albums" && list.records.map((item) => item.AlbumId == parentAlbumId && item)
      );
      const matchedParentItem = matchedParentList?.records && matchedParentList?.records?.find((item) => item.AlbumId == parentAlbumId && item);
      // console.log("matchedParentItem", matchedParentItem);
      dispatch(setPlayList(matchedParentItem));
      localStorage.setItem("playlist", JSON.stringify(matchedParentItem));
      history.push(`/PlayList/${matchedParentItem.albumTitle}`);
    }
  };

  return (
    <>
      {renderConfirmationModel1()}
      {renderConfirmationModel2()}
      {renderConfirmationModel3()}
      <MyModal
        showModel={showModel}
        setModel={setModel}
        // title="Add to Playlist"
        title="Playlist options"
      >
        <div className="modal-body my-2">
          <div className="flex w-full">
            <div
              // className={
              //   "flex flex-col pr-6" +
              //   " " +
              //   `${isMultiSong ? "" : "w-1/2 border-r"}`
              // }
              className={"flex flex-col pr-6" + " " + `${false ? "" : "w-1/2 border-r"}`}
            >
              {matchedPlaylist &&
                matchedPlaylist.records.map((playlist) => (
                  <div className="" key={playlist.PlayListId}>
                    <div className="checkbox">
                      <input
                        type="radio"
                        value={playlist.PlayListName}
                        checked={selectedOption == playlist.PlayListName}
                        id={playlist.PlayListId}
                        onChange={handleOptionChange}
                      />
                      <label className="ml-2 text-sm font-semibold">{playlist.PlayListName}</label>
                    </div>
                  </div>
                ))}
              <button
                onClick={() => handleAddToPlayListClick()}
                className="mt-4 rounded-full bg-black w-full py-2 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Add to playlist
              </button>
            </div>
            {/* {!isMultiSong && ( */}
            <div className="flex flex-col w-1/2 pl-6">
              <Input
                name="firstName"
                value={newPlayListName}
                onChange={(e) => setNewPlayListName(e.target.value)}
                // onBlur={validateInput}
                // error={inputError}
                placeholder="Create new playlist."
                className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-2 pl-6 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 font-semibold"
              />
              <button
                type="submit"
                onClick={() => handleCreatePlaylist()}
                className="rounded-full bg-black w-full py-2 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Create and add
              </button>
            </div>
            {/* )} */}
          </div>
          {!isMultiSong && ShouldShowDeleteBtn && (
            <button
              type="submit"
              onClick={() => handleRemoveFromPlaylist()}
              className="mt-6 w-full text-center md:w-1/2 flex items-center mx-auto justify-center group rounded-full bg-black w-full py-2 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <img className="group-hover:scale-105 w-4 h-4 mr-2 rounded-full transition-all" src={deleteIcon} alt={deleteIcon} />
              Remove from playlist
            </button>
          )}
          {!isMultiSong && item?.AlbumId && (
            <button
              onClick={() => handleGoToAlbumClick()}
              className="mt-6 w-full text-center md:w-1/2 flex items-center mx-auto justify-center group rounded-full bg-black w-full py-2 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <img className="group-hover:scale-105 w-4 h-4 mr-2 rounded-full transition-all" src={info} alt={info} />
              Go to album
            </button>
          )}
        </div>
      </MyModal>
    </>
  );
};

export default AddToPlaylistModal;
