import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isShuffle: false,
  dashboardList: "",
  playList: "",
  songList: "",
  SearchTerm: "",
};

export const rootSlice = createSlice({
  name: "rootReducer",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setShuffle: (state, action) => {
      state.isShuffle = action.payload;
    },
    setDashboard: (state, action) => {
      state.dashboardList = action.payload;
    },
    setPlayList: (state, action) => {
      state.playList = action.payload;
    },
    setSongList: (state, action) => {
      state.songList = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.SearchTerm = action.payload;
    },
  },
});

export const {
  setDashboard,
  setPlayList,
  setSongList,
  setLoading,
  setShuffle,
  setSearchTerm,
} = rootSlice.actions;

export default rootSlice.reducer;
