import Player from "./Bottombar/Player";

export default function bottombar({ showSidebar }) {
  return (
    <div
      className={`h-24 bg-footer border-t border-white border-opacity-5 pl-6 pr-6 pt-[30px] md:pt-6 ${
        showSidebar ? "hidden md:block" : ""
      }`}
    >
      <Player />
    </div>
  );
}
