import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import PlaylistItemBar from "./PlaylistItemBar";

const RenderAnimatedList = ({
  item,
  index,
  updatePlaylist,
  IsMultiSelection,
  handleSongCheckBoxChange,
  SelectedSongList,
  playList,
  reFetchSongs,
}) => {
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShouldRender(true);
    }, 50);
  }, []);
  return (
    <div
      key={index}
      style={{ transitionDelay: `${index * 100}ms` }}
      className={`song-list-item ${shouldRender ? "animate" : ""}`}
    >
      <PlaylistItemBar
        item={item}
        updatePlaylist={updatePlaylist}
        IsMultiSelection={IsMultiSelection}
        handleSongCheckBoxChange={handleSongCheckBoxChange}
        SelectedSongList={SelectedSongList}
        playList={playList}
        reFetchSongs={reFetchSongs}
      />
    </div>
  );
};

export default RenderAnimatedList;
