import React, { useEffect } from "react";
import PlayBox from "components/PlayBox";
import ArtistBox from "components/ArtistBox";

import { NavLink } from "react-router-dom";
import { Icon } from "components/Icons";
import Song from "components/Song";
import Album from "components/Album";
import { useSelector } from "react-redux";

export default function Albums() {
  const { dashboardList } = useSelector((state) => state.rootReducer);
  const targetTitle = "Albums";

  if (dashboardList.length === 0) {
    return <></>;
  }

  return (
    <div>
      <div>
        <h3 className="text-3xl text-white font-bold tracking-tight mb-6">
          Albums
        </h3>
      </div>
      <div className="albumsPage mb-8">
        {dashboardList.map((list) => (
          <>
            {list.title == targetTitle &&
              list.records.map((item) => (
                <Album item={item} key={item.UserPlayListId} />
              ))}
          </>
        ))}
      </div>
    </div>
  );
}
