import React from "react";
import PlayBox from "components/PlayBox";
import ArtistBox from "components/ArtistBox";

import { NavLink } from "react-router-dom";
import { Icon } from "components/Icons";
import Song from "components/Song";
import Album from "components/Album";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import Loader from "components/Loader";
import { getUserData } from "Utils";
import PlaylistAlbum from "components/PlaylistAlbum";

export default function Home() {
  const { dashboardList } = useSelector((state) => state.rootReducer);
  const userData = getUserData();

  const renderDashboardLists = () => {
    if (dashboardList.length === 0) {
      return (
        <>
          <Loader />
        </>
      );
    }

    const linkTo = (title) => {
      if (title == "Best of iChill") {
        return "/BestOfIchill";
      } else if (title == "New Releases & Categories") {
        return "/NewReleases";
      } else if (title == "Albums") {
        return "/Albums";
      } else {
        return "/";
      }
    };

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1875,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1737,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1320,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    return dashboardList.map((list) =>
      list?.records?.length > 0 ? (
        <>
          <div className="">
            <PlayBox title={list.title} see={linkTo(list.title)} />
          </div>
          <div className="mx-2.5">
            <Slider {...settings}>
              {list.records.map((item) =>
                list.title == "My playlists" ? (
                  <PlaylistAlbum item={item} key={item.UserPlayListId} />
                ) : (
                  <Album item={item} key={item.UserPlayListId} />
                )
              )}
            </Slider>
          </div>
        </>
      ) : (
        <></>
      )
    );
  };

  return (
    <div>
      <div>
        <h3 className="text-3xl text-white font-bold tracking-tight mb-6">
          Welcome {userData?.FirstName}
        </h3>
      </div>
      {renderDashboardLists()}
    </div>
  );
}
