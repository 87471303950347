import React from "react";

const MyModal = ({ showModel, setModel, title, children }) => {
  const toggleModal = () => {
    setModel(!showModel);
  };

  return (
    <div>
      {showModel && (
        <div className="text-black fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-800 opacity-50"></div>
          <div className="bg-white px-6 py-4 rounded-lg shadow-lg z-10 w-11/12 sm:w-1/2 animate-fade-slide relative">
            {title && <h3 className="font-bold text-3xl">{title}</h3>}
            <button
              type="button"
              className="popUpClose absolute font-bold"
              onClick={toggleModal}
            >
              ×
            </button>
            {title && <div className="border-b my-2"></div>}
            <div className="text-lg max-h-80 sm:max-h-[80vh] overflow-y-auto">
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyModal;
