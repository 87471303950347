import { Switch, Route, Redirect } from "react-router-dom";
import Navbar from "components/Navbar";
import Header from "components/Header";
import Bottombar from "components/Bottombar";

import Home from "views/Home";
import Search from "views/Search";
import BestOfIchill from "views/BestOfIchill";
import { useState } from "react";
import Albums from "views/Albums";
import PlayList from "views/PlayList";
import NewReleases from "views/NewReleases";
import { useDispatch, useSelector } from "react-redux";
import MyProfile from "views/MyProfile";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { setSongList } from "stores/rootReducer";
import { setCurrentPlaylist } from "stores/player";
import axios from "axios";
import { baseURL } from "Utils";
import Swal from "sweetalert2";
import Payment from "views/Payment";

export default function Content({ setIsUserLoggedIn }) {
  const dispatch = useDispatch();
  const { current } = useSelector((state) => state.player);
  const { songList } = useSelector((state) => state.rootReducer);
  const [showSidebar, setShowSidebar] = useState(false);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    // console.log("result:", result);
    // dropped outside the list
    if (!result.destination) {
      return;
    } else if (
      result.destination?.droppableId == "droppableInPlaylistForSort"
    ) {
      const updatedItems = reorder(
        songList,
        result.source.index,
        result.destination.index
      );
      dispatch(setSongList(updatedItems));
      dispatch(setCurrentPlaylist(updatedItems));
    } else if (
      result.destination?.droppableId.startsWith("droppableInPlaylist")
    ) {
      const item =
        result?.draggableId &&
        JSON.parse(result?.draggableId?.split("item-")[1]);
      const PlayList =
        result?.destination?.droppableId &&
        JSON.parse(
          result?.destination?.droppableId?.split("droppableInPlaylist-")[1]
        );
      axios
        .post(baseURL + "api/addUpdatePlayList", {
          user_id:
            localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
          playlistname: PlayList?.PlayListName,
          songid: item?.SongId,
          pid: PlayList?.PlayListId,
          playlistflag: 1,
          isAgain: 1,
        })
        .then((response) => {
          if (response?.data?.status == "success") {
            Swal.fire({
              icon: "success",
              title: "song added successfully",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
          } else if (
            response?.data?.status == "fail" &&
            response?.data?.message == "Song already added."
          ) {
          }
        })
        .catch((err) => console.error("error:", err));
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div
          className={`wrapper ${showSidebar ? "hightFullOnMobile" : ""} ${
            current ? "" : "hightFullOnSongPlay"
          }`}
        >
          <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
          <div className="flex-auto overflow-auto bg-[url('assets/image/mainbg.jpg')] bg-cover bg-no-repeat bg-center">
            <Navbar
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
              setIsUserLoggedIn={setIsUserLoggedIn}
            />
            <div className="px-4 py-5 md:px-8">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/Payment">
                  <Payment />
                </Route>
                <Route exact path="/MyProfile">
                  <MyProfile setIsUserLoggedIn={setIsUserLoggedIn} />
                </Route>
                <Route exact path="/BestOfIchill">
                  <BestOfIchill />
                </Route>
                <Route exact path="/NewReleases">
                  <NewReleases />
                </Route>
                <Route exact path="/Albums">
                  <Albums />
                </Route>
                <Route exact path="/PlayList/:playListName">
                  <PlayList />
                </Route>
                <Route path="/Search">
                  <Search />
                </Route>
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </DragDropContext>
      {current && <Bottombar showSidebar={showSidebar} />}
    </>
  );
}
