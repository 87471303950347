import Album from "components/Album";
import React from "react";
import { useSelector } from "react-redux";

export default function Search() {
  const { dashboardList, SearchTerm } = useSelector(
    (state) => state.rootReducer
  );
  const targetTitle = "Albums";
  const fullArray =
    dashboardList && [].concat(...dashboardList.map((list) => list.records));

  const filteredArray =
    fullArray &&
    fullArray.filter((list) => {
      const searchTermLower = SearchTerm.toLowerCase();
      return (
        list?.PlayListName?.toLowerCase()?.startsWith(searchTermLower) ||
        list?.albumTitle?.toLowerCase()?.startsWith(searchTermLower) ||
        list?.CategoryName?.toLowerCase()?.startsWith(searchTermLower)
      );
    });

  // console.log("fullArray filteredArray", filteredArray);

  if (!fullArray) {
    return <></>;
  }
  // if (SearchTerm && filteredArray.length === 0) {
  //   return <>No search result found!</>;
  // }

  return (
    <div>
      <div>
        <h3 className="text-3xl text-white font-bold tracking-tight mb-6">
          Search results
        </h3>
      </div>
      {SearchTerm ? (
        filteredArray.length > 3 ? (
          <div className="albumsPage mb-8">
            <>
              {filteredArray.map(
                (item) =>
                  item && <Album item={item} key={item.UserPlayListId} />
              )}
            </>
          </div>
        ) : (
          <div className="belowthreeresult albumsPage mb-8">
            <>
              {filteredArray.map(
                (item) =>
                  item && <Album item={item} key={item.UserPlayListId} />
              )}
            </>
          </div>
        )
      ) : (
        <div className="albumsPage mb-8">
          <>
            {fullArray.map((item) => (
              <Album item={item} key={item.UserPlayListId} />
            ))}
          </>
        </div>
      )}
    </div>
  );
}
