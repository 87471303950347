import Content from "components/Content";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import SignIn from "views/SignIn";
import Register from "views/Register";
import LandingPage from "views/LandingPage";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";

function App() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    localStorage.getItem("USERID") || sessionStorage.getItem("USERID")
  );

  return (
    <Router>
      {isUserLoggedIn ? (
        <Route path="/">
          <Content setIsUserLoggedIn={setIsUserLoggedIn} />
        </Route>
      ) : (
        <Redirect to="/SignIn" />
      )}
      {!isUserLoggedIn && (
        <>
          <Route path="/SignIn">
            <SignIn setIsUserLoggedIn={setIsUserLoggedIn} />
          </Route>
          <Route path="/Register">
            <Register />
          </Route>
        </>
      )}
      {/* <Route path="*">
        <>asdgash</>
      </Route> */}
      {/* <Route exact path="/LandingPage">
        <LandingPage />
      </Route> */}
    </Router>
  );
}

export default App;
