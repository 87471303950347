const HomeIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M12.5 3.247a1 1 0 00-1 0L4 7.577V20h4.5v-6a1 1 0 011-1h5a1 1 0 011 1v6H20V7.577l-7.5-4.33zm-2-1.732a3 3 0 013 0l7.5 4.33a2 2 0 011 1.732V21a1 1 0 01-1 1h-6.5a1 1 0 01-1-1v-6h-3v6a1 1 0 01-1 1H3a1 1 0 01-1-1V7.577a2 2 0 011-1.732l7.5-4.33z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const SearchIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M10.533 1.279c-5.18 0-9.407 4.14-9.407 9.279s4.226 9.279 9.407 9.279c2.234 0 4.29-.77 5.907-2.058l4.353 4.353a1 1 0 101.414-1.414l-4.344-4.344a9.157 9.157 0 002.077-5.816c0-5.14-4.226-9.28-9.407-9.28zm-7.407 9.279c0-4.006 3.302-7.28 7.407-7.28s7.407 3.274 7.407 7.28-3.302 7.279-7.407 7.279-7.407-3.273-7.407-7.28z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const LibIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M14.5 2.134a1 1 0 011 0l6 3.464a1 1 0 01.5.866V21a1 1 0 01-1 1h-6a1 1 0 01-1-1V3a1 1 0 01.5-.866zM16 4.732V20h4V7.041l-4-2.309zM3 22a1 1 0 01-1-1V3a1 1 0 012 0v18a1 1 0 01-1 1zm6 0a1 1 0 01-1-1V3a1 1 0 012 0v18a1 1 0 01-1 1z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const ActiveHomeIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M13.5 1.515a3 3 0 00-3 0L3 5.845a2 2 0 00-1 1.732V21a1 1 0 001 1h6a1 1 0 001-1v-6h4v6a1 1 0 001 1h6a1 1 0 001-1V7.577a2 2 0 00-1-1.732l-7.5-4.33z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const ActiveSearchIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="24"
      width="24"
      className="Svg-sc-1bi12j5-0 hDgDGI search-active-icon"
      viewBox="0 0 24 24"
    >
      <path
        d="M15.356 10.558c0 2.623-2.16 4.75-4.823 4.75-2.664 0-4.824-2.127-4.824-4.75s2.16-4.75 4.824-4.75c2.664 0 4.823 2.127 4.823 4.75z"
        fill="currentColor"
      ></path>
      <path
        d="M1.126 10.558c0-5.14 4.226-9.28 9.407-9.28 5.18 0 9.407 4.14 9.407 9.28a9.157 9.157 0 01-2.077 5.816l4.344 4.344a1 1 0 01-1.414 1.414l-4.353-4.353a9.454 9.454 0 01-5.907 2.058c-5.18 0-9.407-4.14-9.407-9.28zm9.407-7.28c-4.105 0-7.407 3.274-7.407 7.28s3.302 7.279 7.407 7.279 7.407-3.273 7.407-7.28c0-4.005-3.302-7.278-7.407-7.278z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const ActiveCollectionIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="24"
      width="24"
      className="Svg-sc-1bi12j5-0 hDgDGI collection-active-icon"
      viewBox="0 0 24 24"
    >
      <path
        d="M3 22a1 1 0 01-1-1V3a1 1 0 012 0v18a1 1 0 01-1 1zM15.5 2.134A1 1 0 0014 3v18a1 1 0 001 1h6a1 1 0 001-1V6.464a1 1 0 00-.5-.866l-6-3.464zM9 2a1 1 0 00-1 1v18a1 1 0 102 0V3a1 1 0 00-1-1z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const PlusIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="12"
      width="12"
      aria-hidden="true"
      viewBox="0 0 16 16"
      className="Svg-sc-1bi12j5-0 hDgDGI"
    >
      <path
        d="M15.25 8a.75.75 0 01-.75.75H8.75v5.75a.75.75 0 01-1.5 0V8.75H1.5a.75.75 0 010-1.5h5.75V1.5a.75.75 0 011.5 0v5.75h5.75a.75.75 0 01.75.75z"
        fill="black"
      ></path>
    </svg>
  );
};
const MenuHeartIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="12"
      width="12"
      aria-hidden="true"
      viewBox="0 0 16 16"
      className="Svg-sc-1bi12j5-0 hDgDGI"
    >
      <path
        d="M15.724 4.22A4.313 4.313 0 0012.192.814a4.269 4.269 0 00-3.622 1.13.837.837 0 01-1.14 0 4.272 4.272 0 00-6.21 5.855l5.916 7.05a1.128 1.128 0 001.727 0l5.916-7.05a4.228 4.228 0 00.945-3.577z"
        fill="white"
      ></path>
    </svg>
  );
};
const MenuDownloadIcon = ({ size }) => {
  return (
    <svg role="img" height="20" width="20" viewBox="0 0 24 24">
      <path
        d="M12 3a9 9 0 100 18 9 9 0 000-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12z"
        fill="currentColor"
      ></path>
      <path
        d="M12 6.05a1 1 0 011 1v7.486l1.793-1.793a1 1 0 111.414 1.414L12 18.364l-4.207-4.207a1 1 0 111.414-1.414L11 14.536V7.05a1 1 0 011-1z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const BackdIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="22"
      width="22"
      className="Svg-sc-1bi12j5-0 hDgDGI IYDlXmBmmUKHveMzIPCF"
      viewBox="0 0 24 24"
    >
      <path
        d="M15.957 2.793a1 1 0 010 1.414L8.164 12l7.793 7.793a1 1 0 11-1.414 1.414L5.336 12l9.207-9.207a1 1 0 011.414 0z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const NextdIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="22"
      width="22"
      className="Svg-sc-1bi12j5-0 hDgDGI IYDlXmBmmUKHveMzIPCF"
      viewBox="0 0 24 24"
    >
      <path
        d="M8.043 2.793a1 1 0 000 1.414L15.836 12l-7.793 7.793a1 1 0 101.414 1.414L18.664 12 9.457 2.793a1 1 0 00-1.414 0z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const DirIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="16"
      width="16"
      className="Svg-sc-1bi12j5-0 hDgDGI eAXFT6yvz37fvS1lmt6k"
      viewBox="0 0 16 16"
    >
      <path d="M14 6l-6 6-6-6h12z" fill="white"></path>
    </svg>
  );
};
const ExternalIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      className="Svg-sc-1bi12j5-0 hDgDGI"
    >
      <path
        d="M1 2.75A.75.75 0 011.75 2H7v1.5H2.5v11h10.219V9h1.5v6.25a.75.75 0 01-.75.75H1.75a.75.75 0 01-.75-.75V2.75z"
        fill="currentColor"
      ></path>
      <path
        d="M15 1v4.993a.75.75 0 11-1.5 0V3.56L8.78 8.28a.75.75 0 01-1.06-1.06l4.72-4.72h-2.433a.75.75 0 010-1.5H15z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const TopSearchIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="24"
      width="24"
      className="Svg-sc-1bi12j5-0 hDgDGI mOLTJ2mxkzHJj6Y9_na_"
      aria-hidden="true"
      viewBox="0 0 24 24"
    >
      <path
        d="M10.533 1.279c-5.18 0-9.407 4.14-9.407 9.279s4.226 9.279 9.407 9.279c2.234 0 4.29-.77 5.907-2.058l4.353 4.353a1 1 0 101.414-1.414l-4.344-4.344a9.157 9.157 0 002.077-5.816c0-5.14-4.226-9.28-9.407-9.28zm-7.407 9.279c0-4.006 3.302-7.28 7.407-7.28s7.407 3.274 7.407 7.28-3.302 7.279-7.407 7.279-7.407-3.273-7.407-7.28z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const PlayIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      className="Svg-sc-1bi12j5-0 hDgDGI"
    >
      <path
        d="M7.05 3.606l13.49 7.788a.7.7 0 010 1.212L7.05 20.394A.7.7 0 016 19.788V4.212a.7.7 0 011.05-.606z"
        fill="black"
      ></path>
    </svg>
  );
};
const PauseIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="24"
      width="24"
      viewBox="0 0 24 24"
      className="Svg-sc-1bi12j5-0 hDgDGI"
    >
      <path
        d="M5.7 3a.7.7 0 00-.7.7v16.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7V3.7a.7.7 0 00-.7-.7H5.7zm10 0a.7.7 0 00-.7.7v16.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7V3.7a.7.7 0 00-.7-.7h-2.6z"
        fill="black"
      ></path>
    </svg>
  );
};
const PlayerPrevIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      className="Svg-sc-1bi12j5-0 hDgDGI"
    >
      <path
        d="M3.3 1a.7.7 0 01.7.7v5.15l9.95-5.744a.7.7 0 011.05.606v12.575a.7.7 0 01-1.05.607L4 9.149V14.3a.7.7 0 01-.7.7H1.7a.7.7 0 01-.7-.7V1.7a.7.7 0 01.7-.7h1.6z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const PlayerNextIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      className="Svg-sc-1bi12j5-0 hDgDGI"
    >
      <path
        d="M12.7 1a.7.7 0 00-.7.7v5.15L2.05 1.107A.7.7 0 001 1.712v12.575a.7.7 0 001.05.607L12 9.149V14.3a.7.7 0 00.7.7h1.6a.7.7 0 00.7-.7V1.7a.7.7 0 00-.7-.7h-1.6z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const ShuffleIcon = ({ size }) => {
  return (
    <svg role="img" height="16" width="16" viewBox="0 0 16 16">
      <path
        d="M13.151.922a.75.75 0 10-1.06 1.06L13.109 3H11.16a3.75 3.75 0 00-2.873 1.34l-6.173 7.356A2.25 2.25 0 01.39 12.5H0V14h.391a3.75 3.75 0 002.873-1.34l6.173-7.356a2.25 2.25 0 011.724-.804h1.947l-1.017 1.018a.75.75 0 001.06 1.06L15.98 3.75 13.15.922zM.391 3.5H0V2h.391c1.109 0 2.16.49 2.873 1.34L4.89 5.277l-.979 1.167-1.796-2.14A2.25 2.25 0 00.39 3.5z"
        fill="currentColor"
      ></path>
      <path
        d="M7.5 10.723l.98-1.167.957 1.14a2.25 2.25 0 001.724.804h1.947l-1.017-1.018a.75.75 0 111.06-1.06l2.829 2.828-2.829 2.828a.75.75 0 11-1.06-1.06L13.109 13H11.16a3.75 3.75 0 01-2.873-1.34l-.787-.938z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const RepeatIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      className="Svg-sc-1bi12j5-0 hDgDGI"
    >
      <path
        d="M0 4.75A3.75 3.75 0 013.75 1h8.5A3.75 3.75 0 0116 4.75v5a3.75 3.75 0 01-3.75 3.75H9.81l1.018 1.018a.75.75 0 11-1.06 1.06L6.939 12.75l2.829-2.828a.75.75 0 111.06 1.06L9.811 12h2.439a2.25 2.25 0 002.25-2.25v-5a2.25 2.25 0 00-2.25-2.25h-8.5A2.25 2.25 0 001.5 4.75v5A2.25 2.25 0 003.75 12H5v1.5H3.75A3.75 3.75 0 010 9.75v-5z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const RepeatList = ({ size }) => {
  return (
    <svg
      role="img"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      className="Svg-sc-1bi12j5-0 hDgDGI"
    >
      <path
        d="M0 4.75A3.75 3.75 0 013.75 1h8.5A3.75 3.75 0 0116 4.75v5a3.75 3.75 0 01-3.75 3.75H9.81l1.018 1.018a.75.75 0 11-1.06 1.06L6.939 12.75l2.829-2.828a.75.75 0 111.06 1.06L9.811 12h2.439a2.25 2.25 0 002.25-2.25v-5a2.25 2.25 0 00-2.25-2.25h-8.5A2.25 2.25 0 001.5 4.75v5A2.25 2.25 0 003.75 12H5v1.5H3.75A3.75 3.75 0 010 9.75v-5z"
        fill="#26C281"
      ></path>
    </svg>
  );
};
const RepeatOne = ({ size }) => {
  return (
    <svg
      role="img"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      className="Svg-sc-ytk21e-0 haNxPq"
    >
      <path
        d="M0 4.75A3.75 3.75 0 0 1 3.75 1h.75v1.5h-.75A2.25 2.25 0 0 0 1.5 4.75v5A2.25 2.25 0 0 0 3.75 12H5v1.5H3.75A3.75 3.75 0 0 1 0 9.75v-5zM12.25 2.5h-.75V1h.75A3.75 3.75 0 0 1 16 4.75v5a3.75 3.75 0 0 1-3.75 3.75H9.81l1.018 1.018a.75.75 0 1 1-1.06 1.06L6.939 12.75l2.829-2.828a.75.75 0 1 1 1.06 1.06L9.811 12h2.439a2.25 2.25 0 0 0 2.25-2.25v-5a2.25 2.25 0 0 0-2.25-2.25z"
        fill="#26C281"
      ></path>
      <path
        d="M9.12 8V1H7.787c-.128.72-.76 1.293-1.787 1.313V3.36h1.57V8h1.55z"
        fill="#26C281"
      ></path>
    </svg>
  );
};
const WishlistIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="16"
      width="16"
      viewBox="0 0 16 16"
      className="Svg-sc-1bi12j5-0 hDgDGI"
    >
      <path
        d="M1.69 2A4.582 4.582 0 018 2.023 4.583 4.583 0 0111.88.817h.002a4.618 4.618 0 013.782 3.65v.003a4.543 4.543 0 01-1.011 3.84L9.35 14.629a1.765 1.765 0 01-2.093.464 1.762 1.762 0 01-.605-.463L1.348 8.309A4.582 4.582 0 011.689 2zm3.158.252A3.082 3.082 0 002.49 7.337l.005.005L7.8 13.664a.264.264 0 00.311.069.262.262 0 00.09-.069l5.312-6.33a3.043 3.043 0 00.68-2.573 3.118 3.118 0 00-2.551-2.463 3.079 3.079 0 00-2.612.816l-.007.007a1.501 1.501 0 01-2.045 0l-.009-.008a3.082 3.082 0 00-2.121-.861z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const ScreenIcon = ({ size }) => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" fill-rule="evenodd">
        <path
          d="M1 3v9h14V3H1zm0-1h14a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1z"
          fill-rule="nonzero"
        ></path>
        <path d="M10 8h4v3h-4z" fill="currentColor"></path>
      </g>
    </svg>
  );
};
const MicIcon = ({ size }) => {
  return (
    <svg role="img" height="16" width="16" viewBox="0 0 16 16">
      <path
        d="M13.426 2.574a2.831 2.831 0 00-4.797 1.55l3.247 3.247a2.831 2.831 0 001.55-4.797zM10.5 8.118l-2.619-2.62A63303.13 63303.13 0 004.74 9.075L2.065 12.12a1.287 1.287 0 001.816 1.816l3.06-2.688 3.56-3.129zM7.12 4.094a4.331 4.331 0 114.786 4.786l-3.974 3.493-3.06 2.689a2.787 2.787 0 01-3.933-3.933l2.676-3.045 3.505-3.99z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const BestOfIcon = ({ size }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Icon-Set-Filled"
          transform="translate(-310.000000, -413.000000)"
          fill="currentColor"
        >
          <path
            d="M334.585,438.51 C333.141,439.913 331.604,441.37 331.604,441.37 L328.954,436.846 C331.729,436.17 334.128,434.568 335.801,432.388 L338.699,437.334 C338.699,437.334 336.633,437.928 334.585,438.51 L334.585,438.51 Z M326,432 C322.134,432 319,428.866 319,425 C319,421.134 322.134,418 326,418 C329.866,418 333,421.134 333,425 C333,428.866 329.866,432 326,432 L326,432 Z M320.396,441.37 C320.396,441.37 318.859,439.913 317.415,438.51 C315.367,437.928 313.301,437.334 313.301,437.334 L316.199,432.388 C317.872,434.568 320.271,436.17 323.046,436.846 L320.396,441.37 L320.396,441.37 Z M337.021,430.436 C337.824,428.825 338.288,427.021 338.288,425.106 C338.288,418.42 332.786,413 326,413 C319.214,413 313.712,418.42 313.712,425.106 C313.712,427.021 314.176,428.825 314.979,430.436 L309.991,438.946 C309.991,438.946 313.154,439.581 316.363,440.241 C318.503,442.623 320.633,445 320.633,445 L325.219,437.174 C325.479,437.19 325.736,437.213 326,437.213 C326.264,437.213 326.521,437.19 326.781,437.174 L331.367,445 C331.367,445 333.497,442.623 335.637,440.241 C338.846,439.581 342.009,438.946 342.009,438.946 L337.021,430.436 L337.021,430.436 Z M326,420 C323.238,420 321,422.238 321,425 C321,427.762 323.238,430 326,430 C328.762,430 331,427.762 331,425 C331,422.238 328.762,420 326,420 L326,420 Z"
            id="award-2"
          ></path>
        </g>
      </g>
    </svg>
  );
};
const albumsIcon = ({ size }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 40 40"
      enable-background="new 0 0 40 40"
      fill="currentColor"
    >
      <path d="M20 21.57a.694.694 0 0 1-.282-.063L5.434 15.115A.746.746 0 0 1 5 14.413a.75.75 0 0 1 .46-.683l14.286-5.68a.695.695 0 0 1 .508 0l14.285 5.678c.274.11.454.378.46.683a.74.74 0 0 1-.434.705L20.28 21.51a.7.7 0 0 1-.28.06zM7.626 14.467l12.34 5.522 12.408-5.522L20 9.548 7.626 14.47z" />
      <path d="M5.434 20.49a.766.766 0 0 1-.376-.983.717.717 0 0 1 .66-.454c.096 0 .19.02.28.062l13.968 6.25 14.037-6.25a.672.672 0 0 1 .283-.062.71.71 0 0 1 .656.454.764.764 0 0 1-.376.983L20 27.008 5.434 20.49z" />
      <path d="M5.434 25.48a.763.763 0 0 1-.376-.98.717.717 0 0 1 .66-.455c.096 0 .19.02.28.06l13.968 6.25 14.037-6.25c.09-.038.186-.06.283-.06.286 0 .544.18.656.454a.762.762 0 0 1-.375.98L20 32 5.434 25.48z" />
    </svg>
  );
};
const playlistIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
    >
      <path d="M4 5c-.554 0-1 .446-1 1s.446 1 1 1h15c.554 0 1-.446 1-1s-.446-1-1-1H4zm0 7c-.554 0-1 .446-1 1s.446 1 1 1h18c.554 0 1-.446 1-1s-.446-1-1-1H4zm16 5c.017 3.09 0 7.695 0 10l6.484-4.412a.998.998 0 0 0 0-1.414L20 17zM4 19c-.554 0-1 .446-1 1s.446 1 1 1h10c.554 0 1-.446 1-1s-.446-1-1-1H4z" />
    </svg>
  );
};
const AddListIcon = ({ size }) => {
  return (
    <svg role="img" height="16" width="16" viewBox="0 0 16 16">
      <path
        d="M15 15H1v-1.5h14V15zm0-4.5H1V9h14v1.5zm-14-7A2.5 2.5 0 013.5 1h9a2.5 2.5 0 010 5h-9A2.5 2.5 0 011 3.5zm2.5-1a1 1 0 000 2h9a1 1 0 100-2h-9z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const DeviceIcon = ({ size }) => {
  return (
    <svg
      role="img"
      height="16"
      width="16"
      aria-label="Bir cihaza bağlan"
      viewBox="0 0 16 16"
    >
      <path
        d="M6 2.75C6 1.784 6.784 1 7.75 1h6.5c.966 0 1.75.784 1.75 1.75v10.5A1.75 1.75 0 0114.25 15h-6.5A1.75 1.75 0 016 13.25V2.75zm1.75-.25a.25.25 0 00-.25.25v10.5c0 .138.112.25.25.25h6.5a.25.25 0 00.25-.25V2.75a.25.25 0 00-.25-.25h-6.5zm-6 0a.25.25 0 00-.25.25v6.5c0 .138.112.25.25.25H4V11H1.75A1.75 1.75 0 010 9.25v-6.5C0 1.784.784 1 1.75 1H4v1.5H1.75zM4 15H2v-1.5h2V15z"
        fill="currentColor"
      ></path>
      <path
        d="M13 10a2 2 0 11-4 0 2 2 0 014 0zm-1-5a1 1 0 11-2 0 1 1 0 012 0z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const SoundFullIcon = ({ size }) => {
  return (
    <svg
      role="presentation"
      height="16"
      width="16"
      aria-label="Ses seviyesi yüksek"
      id="volume-icon"
      viewBox="0 0 16 16"
    >
      <path
        d="M9.741.85a.75.75 0 01.375.65v13a.75.75 0 01-1.125.65l-6.925-4a3.642 3.642 0 01-1.33-4.967 3.639 3.639 0 011.33-1.332l6.925-4a.75.75 0 01.75 0zm-6.924 5.3a2.139 2.139 0 000 3.7l5.8 3.35V2.8l-5.8 3.35zm8.683 4.29V5.56a2.75 2.75 0 010 4.88z"
        fill="currentColor"
      ></path>
      <path
        d="M11.5 13.614a5.752 5.752 0 000-11.228v1.55a4.252 4.252 0 010 8.127v1.55z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const SoundMidIcon = ({ size }) => {
  return (
    <svg
      role="presentation"
      height="16"
      width="16"
      aria-label="Ses seviyesi orta"
      id="volume-icon"
      viewBox="0 0 16 16"
    >
      <path
        d="M9.741.85a.75.75 0 01.375.65v13a.75.75 0 01-1.125.65l-6.925-4a3.642 3.642 0 01-1.33-4.967 3.639 3.639 0 011.33-1.332l6.925-4a.75.75 0 01.75 0zm-6.924 5.3a2.139 2.139 0 000 3.7l5.8 3.35V2.8l-5.8 3.35zm8.683 6.087a4.502 4.502 0 000-8.474v1.65a2.999 2.999 0 010 5.175v1.649z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const SoundLowIcon = ({ size }) => {
  return (
    <svg
      role="presentation"
      height="16"
      width="16"
      aria-label="Ses seviyesi düşük"
      id="volume-icon"
      viewBox="0 0 16 16"
    >
      <path
        d="M9.741.85a.75.75 0 01.375.65v13a.75.75 0 01-1.125.65l-6.925-4a3.642 3.642 0 01-1.33-4.967 3.639 3.639 0 011.33-1.332l6.925-4a.75.75 0 01.75 0zm-6.924 5.3a2.139 2.139 0 000 3.7l5.8 3.35V2.8l-5.8 3.35zm8.683 4.29V5.56a2.75 2.75 0 010 4.88z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const FullScreenicon = ({ size }) => {
  return (
    <svg role="img" height="16" width="16" viewBox="0 0 16 16">
      <path
        d="M6.53 9.47a.75.75 0 010 1.06l-2.72 2.72h1.018a.75.75 0 010 1.5H1.25v-3.579a.75.75 0 011.5 0v1.018l2.72-2.72a.75.75 0 011.06 0zm2.94-2.94a.75.75 0 010-1.06l2.72-2.72h-1.018a.75.75 0 110-1.5h3.578v3.579a.75.75 0 01-1.5 0V3.81l-2.72 2.72a.75.75 0 01-1.06 0z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const SoundMuteicon = ({ size }) => {
  return (
    <svg
      role="presentation"
      height="16"
      width="16"
      aria-label="Ses kapalı"
      id="volume-icon"
      viewBox="0 0 16 16"
    >
      <path
        d="M13.86 5.47a.75.75 0 00-1.061 0l-1.47 1.47-1.47-1.47A.75.75 0 008.8 6.53L10.269 8l-1.47 1.47a.75.75 0 101.06 1.06l1.47-1.47 1.47 1.47a.75.75 0 001.06-1.06L12.39 8l1.47-1.47a.75.75 0 000-1.06z"
        fill="currentColor"
      ></path>
      <path
        d="M10.116 1.5A.75.75 0 008.991.85l-6.925 4a3.642 3.642 0 00-1.33 4.967 3.639 3.639 0 001.33 1.332l6.925 4a.75.75 0 001.125-.649v-1.906a4.73 4.73 0 01-1.5-.694v1.3L2.817 9.852a2.141 2.141 0 01-.781-2.92c.187-.324.456-.594.78-.782l5.8-3.35v1.3c.45-.313.956-.55 1.5-.694V1.5z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
const PlayerPlayIcon = ({ size }) => {
  return (
    <svg role="img" height="20" width="20" viewBox="0 0 24 24">
      <path
        d="M7.05 3.606l13.49 7.788a.7.7 0 010 1.212L7.05 20.394A.7.7 0 016 19.788V4.212a.7.7 0 011.05-.606z"
        fill="black"
      ></path>
    </svg>
  );
};
const PlayerPauseIcon = ({ size }) => {
  return (
    <svg role="img" height="20" width="20" viewBox="0 0 24 24">
      <path
        d="M5.7 3a.7.7 0 00-.7.7v16.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7V3.7a.7.7 0 00-.7-.7H5.7zm10 0a.7.7 0 00-.7.7v16.6a.7.7 0 00.7.7h2.6a.7.7 0 00.7-.7V3.7a.7.7 0 00-.7-.7h-2.6z"
        fill="black"
      ></path>
    </svg>
  );
};
const Delete = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
      width="20"
      height="20"
      viewBox="3 2 18 20"
    >
      <path
        d="M10 11v6M14 11v6M4 7h16M6 7h12v11a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V7zM9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2H9V5z"
        stroke="#26C281"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const Edit = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 1919.96 1920"
    >
      <path
        d="M277.974 49.076c65.267-65.379 171.733-65.49 237.448 0l232.186 232.187 1055.697 1055.809L1919.958 1920l-582.928-116.653-950.128-950.015 79.15-79.15 801.792 801.68 307.977-307.976-907.362-907.474L281.22 747.65 49.034 515.464c-65.379-65.603-65.379-172.069 0-237.448zm1376.996 1297.96-307.977 307.976 45.117 45.116 384.999 77.023-77.023-385-45.116-45.116zM675.355 596.258l692.304 692.304-79.149 79.15-692.304-692.305 79.149-79.15zM396.642 111.88c-14.33 0-28.547 5.374-39.519 16.345l-228.94 228.94c-21.718 21.718-21.718 57.318 0 79.149l153.038 153.037 308.089-308.09-153.037-153.036c-10.972-10.971-25.301-16.345-39.63-16.345z"
        fill-rule="evenodd"
        fill="#26C281"
      />
    </svg>
  );
};
const Share = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="1 0 23 23"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.65.8C18.482-.73 16.01.08 16.01 1.993v1.92c-3.773-.045-6.353 1.252-8.072 3.063-1.838 1.935-2.593 4.355-2.91 6.01-.158.827.384 1.428.955 1.635.544.198 1.272.113 1.758-.442 1.38-1.576 4.059-3.776 8.268-3.633v2.46c0 1.913 2.474 2.724 3.64 1.194l3.744-4.91a2.945 2.945 0 0 0 0-3.58L19.65.8zM7.482 11.61c1.805-1.576 4.397-3.054 8.527-3.054h1.012c.558 0 1.01.445 1.01.994v3.457l3.744-4.91a.981.981 0 0 0 0-1.194l-3.743-4.91v2.914c0 .55-.453.995-1.012.995h-1.01c-4.55 0-6.594 2.431-6.594 2.431-.94.991-1.546 2.169-1.934 3.277z"
        fill="#26C281"
      />
      <path
        d="M7 1.004H4a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3v-3a1 1 0 1 0-2 0v3a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-16a1 1 0 0 1 1-1h3a1 1 0 0 0 0-2z"
        fill="#26C281"
      />
    </svg>
  );
};
const MusicPuls = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45">
      <circle cx="22.5" cy="22.5" r="20" fill="#3e3e3e">
        <animate
          attributeName="r"
          from="20"
          to="22.5"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="1"
          to="0.2"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
      <svg
        x="10"
        y="11"
        width="22"
        height="22"
        viewBox="0 0 512 512"
        fill="#ffff"
      >
        <path d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7v72V368c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V147L192 223.8V432c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6V200 128c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z" />
      </svg>
    </svg>
  );
};
const OptionVertical = ({ size }) => {
  return (
    <svg
      fill="#000000"
      width="20"
      height="20"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.15 28.012v-0.85c0.019-0.069 0.050-0.131 0.063-0.2 0.275-1.788 1.762-3.2 3.506-3.319 1.95-0.137 3.6 0.975 4.137 2.787 0.069 0.238 0.119 0.488 0.181 0.731v0.85c-0.019 0.056-0.050 0.106-0.056 0.169-0.269 1.65-1.456 2.906-3.081 3.262-0.125 0.025-0.25 0.063-0.375 0.094h-0.85c-0.056-0.019-0.113-0.050-0.169-0.056-1.625-0.262-2.862-1.419-3.237-3.025-0.037-0.156-0.081-0.3-0.119-0.444zM20.038 3.988l-0 0.85c-0.019 0.069-0.050 0.131-0.056 0.2-0.281 1.8-1.775 3.206-3.538 3.319-1.944 0.125-3.588-1-4.119-2.819-0.069-0.231-0.119-0.469-0.175-0.7v-0.85c0.019-0.056 0.050-0.106 0.063-0.162 0.3-1.625 1.244-2.688 2.819-3.194 0.206-0.069 0.425-0.106 0.637-0.162h0.85c0.056 0.019 0.113 0.050 0.169 0.056 1.631 0.269 2.863 1.419 3.238 3.025 0.038 0.15 0.075 0.294 0.113 0.437zM20.037 15.575v0.85c-0.019 0.069-0.050 0.131-0.063 0.2-0.281 1.794-1.831 3.238-3.581 3.313-1.969 0.087-3.637-1.1-4.106-2.931-0.050-0.194-0.094-0.387-0.137-0.581v-0.85c0.019-0.069 0.050-0.131 0.063-0.2 0.275-1.794 1.831-3.238 3.581-3.319 1.969-0.094 3.637 1.1 4.106 2.931 0.050 0.2 0.094 0.394 0.137 0.588z"></path>
    </svg>
  );
};
const MultiSelect = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 60 60"
      fill="#ffff"
    >
      <g data-name="Group 11157">
        <path data-name="Rectangle 1" fill="none" d="M0 0h60v60H0z" />
        <g data-name="add-to-playlist (1)">
          <path
            data-name="Path 10384"
            d="M19.979 5.477a2.006 2.006 0 0 0-.993 1.052c-.095.236-.13 6.3-.13 19.816 0 21.223.035 20.052-.662 21.435a6.362 6.362 0 0 1-2.282 2.27 8.312 8.312 0 0 1-4.978.674 5.514 5.514 0 0 1-2.648-1.431 3.426 3.426 0 0 1-1.076-2.719 2.962 2.962 0 0 1 .39-1.8 5.176 5.176 0 0 1 1.182-1.525 7 7 0 0 1 5.545-1.974c1.3.035 1.466.012 1.773-.213a1.878 1.878 0 0 0 .035-3.145c-.709-.52-3.275-.568-5.2-.095a10.579 10.579 0 0 0-6.786 5.356 7.836 7.836 0 0 0 .307 7.389 10.007 10.007 0 0 0 2.731 2.731 11.944 11.944 0 0 0 4.044 1.3 12.691 12.691 0 0 0 5.947-.946 9.547 9.547 0 0 0 5.344-7.07c.083-.508.118-7.59.095-20.43-.035-19.626-.035-19.65-.284-19.969a3.032 3.032 0 0 0-.544-.556 2.193 2.193 0 0 0-1.81-.15z"
          />
          <path
            data-name="Path 10385"
            d="M25.655 5.466a1.919 1.919 0 0 0-.686 2.932 2.023 2.023 0 0 0 1.655.7 7.674 7.674 0 0 1 5.06 2.175 7.508 7.508 0 0 1 2.294 5c.106 1.182.248 1.5.887 1.974a2.139 2.139 0 0 0 2.034 0c.721-.532.816-.792.8-2.093a11.258 11.258 0 0 0-10.57-10.795 3.544 3.544 0 0 0-1.474.107z"
          />
          <path
            data-name="Path 10386"
            d="M38.896 9.261a1.945 1.945 0 0 0-1.076 2.14 2.023 2.023 0 0 0 1.23 1.36c.3.095 3.039.118 8.442.095 7.862-.035 8-.035 8.323-.284a1.669 1.669 0 0 0 .816-1.584 1.669 1.669 0 0 0-.816-1.584c-.319-.248-.449-.248-8.442-.272-6.573-.025-8.193-.001-8.477.129z"
          />
          <path
            data-name="Path 10387"
            d="M33.221 21.557a2.006 2.006 0 0 0-.993 1.052 17.107 17.107 0 0 0-.13 2.861V28l-2.708.035c-2.979.035-3.015.047-3.606.851a2.139 2.139 0 0 0 0 2.034c.591.8.627.816 3.594.851l2.707.035.035 2.707c.035 2.968.047 3 .851 3.594a2.139 2.139 0 0 0 2.034 0c.8-.591.816-.627.851-3.594l.035-2.707 2.707-.035c2.968-.035 3-.047 3.594-.851a2.139 2.139 0 0 0 0-2.034c-.591-.8-.627-.816-3.594-.851L35.891 28l-.035-2.707c-.035-2.956-.047-3-.828-3.582a2.193 2.193 0 0 0-1.807-.154z"
          />
          <path
            data-name="Path 10388"
            d="M42.679 22.503a1.944 1.944 0 0 0-1.076 2.14 2.024 2.024 0 0 0 1.23 1.36c.3.095 2.471.118 6.55.095 5.959-.035 6.113-.047 6.432-.284a1.669 1.669 0 0 0 .816-1.584 1.669 1.669 0 0 0-.816-1.584c-.319-.248-.473-.248-6.55-.272-5.001-.025-6.302-.001-6.586.129z"
          />
          <path
            data-name="Path 10389"
            d="M42.679 35.745a1.944 1.944 0 0 0-1.076 2.14 2.024 2.024 0 0 0 1.23 1.36c.3.095 2.471.118 6.55.095 5.959-.035 6.113-.047 6.432-.284a1.669 1.669 0 0 0 .816-1.584 1.669 1.669 0 0 0-.816-1.584c-.319-.248-.473-.248-6.55-.272-5.001-.025-6.302-.001-6.586.129z"
          />
          <path
            data-name="Path 10390"
            d="M27.546 48.987a1.944 1.944 0 0 0-1.076 2.14 2.023 2.023 0 0 0 1.23 1.36c.3.095 4.741.118 14.117.095 13.6-.035 13.679-.035 14-.284a1.669 1.669 0 0 0 .816-1.584 1.669 1.669 0 0 0-.816-1.584c-.319-.248-.39-.248-14.117-.272-11.292-.025-13.87-.001-14.154.129z"
          />
        </g>
      </g>
    </svg>
  );
};
const MultiSelectActive = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 60 60"
      fill="#26C281"
    >
      <g data-name="Group 11157">
        <path data-name="Rectangle 1" fill="none" d="M0 0h60v60H0z" />
        <g data-name="add-to-playlist (1)">
          <path
            data-name="Path 10384"
            d="M19.979 5.477a2.006 2.006 0 0 0-.993 1.052c-.095.236-.13 6.3-.13 19.816 0 21.223.035 20.052-.662 21.435a6.362 6.362 0 0 1-2.282 2.27 8.312 8.312 0 0 1-4.978.674 5.514 5.514 0 0 1-2.648-1.431 3.426 3.426 0 0 1-1.076-2.719 2.962 2.962 0 0 1 .39-1.8 5.176 5.176 0 0 1 1.182-1.525 7 7 0 0 1 5.545-1.974c1.3.035 1.466.012 1.773-.213a1.878 1.878 0 0 0 .035-3.145c-.709-.52-3.275-.568-5.2-.095a10.579 10.579 0 0 0-6.786 5.356 7.836 7.836 0 0 0 .307 7.389 10.007 10.007 0 0 0 2.731 2.731 11.944 11.944 0 0 0 4.044 1.3 12.691 12.691 0 0 0 5.947-.946 9.547 9.547 0 0 0 5.344-7.07c.083-.508.118-7.59.095-20.43-.035-19.626-.035-19.65-.284-19.969a3.032 3.032 0 0 0-.544-.556 2.193 2.193 0 0 0-1.81-.15z"
          />
          <path
            data-name="Path 10385"
            d="M25.655 5.466a1.919 1.919 0 0 0-.686 2.932 2.023 2.023 0 0 0 1.655.7 7.674 7.674 0 0 1 5.06 2.175 7.508 7.508 0 0 1 2.294 5c.106 1.182.248 1.5.887 1.974a2.139 2.139 0 0 0 2.034 0c.721-.532.816-.792.8-2.093a11.258 11.258 0 0 0-10.57-10.795 3.544 3.544 0 0 0-1.474.107z"
          />
          <path
            data-name="Path 10386"
            d="M38.896 9.261a1.945 1.945 0 0 0-1.076 2.14 2.023 2.023 0 0 0 1.23 1.36c.3.095 3.039.118 8.442.095 7.862-.035 8-.035 8.323-.284a1.669 1.669 0 0 0 .816-1.584 1.669 1.669 0 0 0-.816-1.584c-.319-.248-.449-.248-8.442-.272-6.573-.025-8.193-.001-8.477.129z"
          />
          <path
            data-name="Path 10387"
            d="M33.221 21.557a2.006 2.006 0 0 0-.993 1.052 17.107 17.107 0 0 0-.13 2.861V28l-2.708.035c-2.979.035-3.015.047-3.606.851a2.139 2.139 0 0 0 0 2.034c.591.8.627.816 3.594.851l2.707.035.035 2.707c.035 2.968.047 3 .851 3.594a2.139 2.139 0 0 0 2.034 0c.8-.591.816-.627.851-3.594l.035-2.707 2.707-.035c2.968-.035 3-.047 3.594-.851a2.139 2.139 0 0 0 0-2.034c-.591-.8-.627-.816-3.594-.851L35.891 28l-.035-2.707c-.035-2.956-.047-3-.828-3.582a2.193 2.193 0 0 0-1.807-.154z"
          />
          <path
            data-name="Path 10388"
            d="M42.679 22.503a1.944 1.944 0 0 0-1.076 2.14 2.024 2.024 0 0 0 1.23 1.36c.3.095 2.471.118 6.55.095 5.959-.035 6.113-.047 6.432-.284a1.669 1.669 0 0 0 .816-1.584 1.669 1.669 0 0 0-.816-1.584c-.319-.248-.473-.248-6.55-.272-5.001-.025-6.302-.001-6.586.129z"
          />
          <path
            data-name="Path 10389"
            d="M42.679 35.745a1.944 1.944 0 0 0-1.076 2.14 2.024 2.024 0 0 0 1.23 1.36c.3.095 2.471.118 6.55.095 5.959-.035 6.113-.047 6.432-.284a1.669 1.669 0 0 0 .816-1.584 1.669 1.669 0 0 0-.816-1.584c-.319-.248-.473-.248-6.55-.272-5.001-.025-6.302-.001-6.586.129z"
          />
          <path
            data-name="Path 10390"
            d="M27.546 48.987a1.944 1.944 0 0 0-1.076 2.14 2.023 2.023 0 0 0 1.23 1.36c.3.095 4.741.118 14.117.095 13.6-.035 13.679-.035 14-.284a1.669 1.669 0 0 0 .816-1.584 1.669 1.669 0 0 0-.816-1.584c-.319-.248-.39-.248-14.117-.272-11.292-.025-13.87-.001-14.154.129z"
          />
        </g>
      </g>
    </svg>
  );
};

const Icon = ({ name, size = 24 }) => {
  const icons = {
    home: HomeIcon,
    search: SearchIcon,
    lib: LibIcon,
    acthome: ActiveHomeIcon,
    actsearch: ActiveSearchIcon,
    actcollection: ActiveCollectionIcon,
    plus: PlusIcon,
    menuheart: MenuHeartIcon,
    menudownload: MenuDownloadIcon,
    back: BackdIcon,
    next: NextdIcon,
    dir: DirIcon,
    external: ExternalIcon,
    topsearch: TopSearchIcon,
    play: PlayIcon,
    pause: PauseIcon,
    playernext: PlayerNextIcon,
    playerprev: PlayerPrevIcon,
    shuffle: ShuffleIcon,
    repeat: RepeatIcon,
    repeatList: RepeatList,
    repeatOne: RepeatOne,
    wishlist: WishlistIcon,
    screen: ScreenIcon,
    mic: MicIcon,
    addlist: AddListIcon,
    device: DeviceIcon,
    full: SoundFullIcon,
    mid: SoundMidIcon,
    low: SoundLowIcon,
    fullscreen: FullScreenicon,
    mute: SoundMuteicon,
    pplay: PlayerPlayIcon,
    ppause: PlayerPauseIcon,
    BestOf: BestOfIcon,
    albumsIcon: albumsIcon,
    playlistIcon: playlistIcon,
    delete: Delete,
    edit: Edit,
    share: Share,
    musicPuls: MusicPuls,
    optionVertical: OptionVertical,
    multiSelect: MultiSelect,
    multiSelectActive: MultiSelectActive,
  };

  const Component = icons[name];
  return <Component size={size} />;
};
export { Icon };
