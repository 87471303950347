import Menu from "components/Header/Menu";
import { Icon } from "components/Icons";
import DownloadApp from "./Header/DownloadApp";
import { useState } from "react";
import logo from "assets/image/ichilli_logo_green.png";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useEffect } from "react";
import axios from "axios";
import { baseURL } from "Utils";
import { setDashboard } from "stores/rootReducer";
import { useDispatch } from "react-redux";
import PlaylistMenu from "./Header/PlaylistMenu";
import { Link } from "react-router-dom";
import MyModal from "./Modal";
import Input from "./Input";
import Swal from "sweetalert2";

export default function Header({ showSidebar, setShowSidebar }) {
  const userId =
    localStorage.getItem("USERID") || sessionStorage.getItem("USERID");
  const dispatch = useDispatch();
  const [isBelow768, setIsBelow768] = useState(false);
  const [ImportModal, setImportModal] = useState(false);
  const [ImportUrl, setImportUrl] = useState();

  useEffect(() => {
    window.addEventListener("resize", setIsBelow768(window.innerWidth < 768));
    return () => {
      window.removeEventListener(
        "resize",
        setIsBelow768(window.innerWidth < 768)
      );
    };
  }, []);
  useEffect(() => {
    axios
      .post(baseURL + "api/userDashboard", {
        user_id: userId,
      })
      .then((response) => {
        // console.log("header response", response);
        dispatch(setDashboard(response?.data?.result));
      })
      .catch((err) => console.error("error:", err));
  }, []);
  const SetUserDashboardFunc = () => {
    axios
      .post(baseURL + "api/userDashboard", {
        user_id: userId,
      })
      .then((response) => {
        dispatch(setDashboard(response?.data?.result));
      })
      .catch((err) => console.error("error:", err));
  };
  const handleYesClick = () => {
    if (ImportUrl) {
      if (
        ImportUrl.indexOf("http://") == 0 ||
        ImportUrl.indexOf("https://") == 0
      ) {
        axios
          .get(ImportUrl + "&shareToUserId=" + userId)
          .then((response) => {
            if (response.data.status == "fail") {
              if (response.data.message == "Invalid Playlist Details.") {
                Swal.fire({
                  icon: "error",
                  title: "Playlist unavailable or deleted",
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: response.data.message,
                  showConfirmButton: true,
                });
              }
            } else {
              let resultArr = response.data.Result;
              if (response.data.message == "Playlist already exist.") {
                Swal.fire({
                  icon: "error",
                  title: "Playlist already exist.",
                  showConfirmButton: true,
                });
              } else if (
                resultArr.length != 0 &&
                response.data.status == "Success"
              ) {
                SetUserDashboardFunc();
                setImportModal(false);
                setImportUrl("");
                Swal.fire({
                  icon: "success",
                  title: "Playlist imported successfully",
                  showConfirmButton: false,
                  timer: 2500,
                  timerProgressBar: true,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: response.data.message,
                  showConfirmButton: true,
                });
              }
            }
          })
          .catch((err) =>
            Swal.fire({
              icon: "error",
              title: "Please enter valid playlist link",
              showConfirmButton: true,
            })
          );
      } else {
        Swal.fire({
          icon: "error",
          title: "Please enter valid playlist link",
          showConfirmButton: true,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please enter valid playlist link",
        showConfirmButton: true,
      });
    }
  };

  // console.log("resize", window.innerWidth);
  return (
    <>
      <MyModal
        showModel={ImportModal}
        setModel={() => {
          setImportModal(false);
          setImportUrl("");
        }}
        title="Import Playlist"
      >
        <Input
          name="ImportUrl"
          value={ImportUrl}
          onChange={(e) => setImportUrl(e.target.value)}
          // onBlur={validateInput}
          // error={inputError}
          placeholder="Past playlist URL here."
          className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white mt-4 py-2 pl-6 pr-6 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 font-semibold"
        />
        <div className="w-full pt-2">
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              setImportModal(false);
              setImportUrl("");
            }}
          >
            Cancel
          </button>
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleYesClick}
          >
            Submit
          </button>
        </div>
      </MyModal>
      <Transition
        as={Fragment}
        show={isBelow768 ? showSidebar : true}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div
          className={`md:flex overflow-auto bg-black w-full md:w-1/5 flex-col flex-shrink-0 sidebarAnimation z-40  ease-in-out duration-300 
        ${showSidebar ? "" : "hidden"}
        `}
        >
          <Link
            to="/"
            className="flex justify-between w-full pt-3 pb-6 pl-6 pr-6"
          >
            <img className="h-14 w-auto" src={logo} />
            <div className="md:hidden">
              {showSidebar ? (
                <button
                  className="flex text-4xl items-center cursor-pointer z-50 text-white font-black"
                  onClick={() => setShowSidebar(!showSidebar)}
                >
                  x
                </button>
              ) : (
                <svg
                  onClick={() => setShowSidebar(!showSidebar)}
                  className="z-30 flex items-center cursor-pointer"
                  fill="#fffff"
                  viewBox="0 0 100 80"
                  width="30"
                  height="40"
                >
                  <rect width="100" height="10"></rect>
                  <rect y="30" width="100" height="10"></rect>
                  <rect y="60" width="100" height="10"></rect>
                </svg>
              )}
            </div>
          </Link>
          <Menu setShowSidebar={setShowSidebar} />
          {/* <nav className="mt-8 px-6">
            <ul>
              <li>
                <a
                  className="flex items-center gap-x-3 text-sm font-bold text-link hover:text-white transition-colors group"
                  href="#"
                >
                  <span className="bg-white p-[6px] bg-opacity-70 group-hover:bg-opacity-90">
                    <Icon name="plus" />
                  </span>
                  Create Playlist
                </a>
                <a
                  className="flex items-center gap-x-3 text-sm font-bold text-link hover:text-white mt-4 transition-colors group"
                  href="#"
                >
                  <span className="bg-gradient-to-r from-gra1 to-gra2  p-[6px] opacity-70 group-hover:opacity-100">
                    <Icon name="menuheart" />
                  </span>
                  Favorite Songs
                </a>
              </li>
            </ul>
          </nav> */}
          <div className="h-[1px] bg-active mt-5 mx-6"></div>
          <div className="flex items-center justify-between font-bold mt-5 mx-6">
            My Playlists{" "}
            <button
              onClick={() => setImportModal(true)}
              className="rounded-full bg-card hover:bg-card py-1 px-4 text-xs font-semibold text-white shadow-sm hover:scale-105"
            >
              import
            </button>
          </div>
          <nav
            className="pt-3 overflow-y-auto scrollbar-hide"
            onClick={() => setShowSidebar(false)}
          >
            <PlaylistMenu />
          </nav>
          {/* <span className="px-6 pt-3 ">
            <DownloadApp />
          </span> */}
        </div>
      </Transition>
    </>
  );
}
