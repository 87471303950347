import axios from "axios";
import React from "react";
import FacebookLogin from "react-facebook-login";
import { useHistory } from "react-router-dom";
import { baseURL } from "Utils";

const AuthWithFbButton = ({ setIsUserLoggedIn }) => {
  const history = useHistory();
  const responseFacebook = (response) => {
    // console.log("responseFacebook", response);

    axios
      .post(baseURL + "registerweb", {
        fname: response.name,
        lname: response.name,
        email: response.email,
        password: response.email,
      })
      .then((responseRegister) => {
        axios
          .post(baseURL + "loginweb", {
            email: response.email,
            pass: response.email,
            deviceToken: "",
            deviceType: "web",
            againlogin: "0",
          })
          .then((responseSignIn) => {
            sessionStorage.setItem("USERID", JSON.stringify(responseSignIn));
            setIsUserLoggedIn(true);
            history.push("/");
          })
          .catch((err) => console.error("login ==> Error fetching data:", err));
      });
  };
  return (
    <FacebookLogin
      // appId="792517981848575"
      appId="741783570863890"
      // autoLoad={true}
      fields="name,email,picture"
      // scope="public_profile,user_friends,user_actions.books"
      callback={responseFacebook}
      cssClass="bg-4 bg-4-fx font-semibold"
      icon="fa-facebook pr-3"
      textButton="SIGN IN WITH FACEBOOK"
    />
  );
};

export default AuthWithFbButton;
