import React from "react";
import { NavLink } from "react-router-dom";

export default function PlayBox({ title, see = false, items }) {
  return (
    <div className="flex justify-between items-end mb-4 w-full">
      <NavLink to={see}>
        <h3 className="text-2xl text-white font-bold hover:underline tracking-tight">
          {title}
        </h3>
      </NavLink>

      <NavLink
        className={
          "text-link text-[12px] font-semibold tracking-widest bottom-0"
        }
        to={see}
      >
        SEE ALL
      </NavLink>
    </div>
  );
}
