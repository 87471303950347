import { useState } from "react";
import { useEffect } from "react";
export const baseURL =
  process.env.REACT_APP_BASE_URL || "https://upgrade.ichillmusic.com/";
export function secondsToTime(seconds) {
  return new Date(1000 * seconds).toISOString().substr(14, 5);
}
export function getUserData() {
  return JSON.parse(localStorage.getItem("USER"));
}
export function getUserSubscriptionData() {
  return JSON.parse(localStorage.getItem("SubscriberUser"));
}
export const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (err) {
        setError(err.message || "An error occurred while fetching the data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, loading, error };
};
