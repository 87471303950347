import axios from "axios";
import Input from "components/Input";
import Loader, { LoaderSmall } from "components/Loader";
import MyModal from "components/Modal";
import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { baseURL } from "Utils";
import { getUserData } from "Utils";

const MyProfile = ({ setIsUserLoggedIn }) => {
  const userData = getUserData();
  const history = useHistory();
  const [formData, setFormData] = useState({
    firstName: userData?.FirstName,
    lastName: userData?.LastName,
    email: userData?.Email,
    password: "",
  });
  const [inputError, setInputError] = useState({});
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [IsDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
  const [IsUpdateBtnLoading, setIsUpdateBtnLoading] = useState(false);

  const updateData = () => {
    setIsUpdateBtnLoading(true);
    axios
      .post(baseURL + "registrationv1", {
        userid: userData?.UserId,
        fname: formData?.firstName,
        lname: formData?.lastName,
        email: formData?.email,
        phone: "",
        password: formData?.password,
      })
      .then((response) => {
        if (response?.data?.Status == "success") {
          // setData(response.data);
          localStorage.setItem(
            "USERID",
            response?.data?.Result?.UserDetail?.UserId
          );
          sessionStorage.removeItem("USERID");
          localStorage.setItem(
            "USER",
            JSON.stringify(response?.data?.Result?.UserDetail)
          );
          history.push("/");
          setIsUpdateBtnLoading(false);
          Swal.fire({
            icon: "success",
            title: "Your profile has been updated",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        } else {
          setIsUpdateBtnLoading(false);
          Swal.fire({
            icon: "error",
            title: "failed.",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        setIsUpdateBtnLoading(false);
        console.error("error:", err);
      });
  };
  function checkValuesPresent(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          return false;
        }
      }
    }
    return true;
  }
  const handleSubmit = () => {
    if (checkValuesPresent(formData) && !checkValuesPresent(inputError)) {
      updateData();
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please complete the form.",
        showConfirmButton: true,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setInputError((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };
  const validateInput = (e) => {
    const { name, value } = e.target;
    if (!formData[name]) {
      setInputError((prevData) => ({
        ...prevData,
        [name]: `${[name]} is required`,
      }));
    } else {
      setInputError((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    }
  };
  const renderDeleteModal = () => {
    return (
      <MyModal
        showModel={isDeleteModal}
        setModel={setIsDeleteModal}
        title="Please Confirm"
      >
        <p className=" pt-2">Are you sure you want to delete your account?</p>
        <div className="w-full pt-4">
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => setIsDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className={`p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
              IsDeleteBtnLoading ? "cursor-not-allowed" : ""
            }`}
            onClick={() => handleDeleteClick()}
          >
            {IsDeleteBtnLoading && <LoaderSmall />}
            Delete
          </button>
        </div>
      </MyModal>
    );
  };
  const handleDeleteClick = () => {
    setIsDeleteBtnLoading(true);
    axios
      .post(baseURL + "api/DeleteCustomerProfile", {
        userid:
          localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
      })
      .then((response) => {
        if (response?.data?.status == "success") {
          setIsDeleteModal(false);
          localStorage.removeItem("USERID");
          sessionStorage.removeItem("USERID");
          setIsUserLoggedIn(false);
          history.push("/SignIn");
          setIsDeleteBtnLoading(false);
          setTimeout(() => {
            Swal.fire({
              icon: "success",
              title: "account deleted successfully",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
          }, 500);
        } else {
          setIsDeleteModal(false);
          setIsDeleteBtnLoading(false);
          Swal.fire({
            icon: "error",
            title: "failed.",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true,
          });
        }
      })
      .catch((err) => {
        console.error("error:", err);
        setIsDeleteBtnLoading(false);
      });
  };
  return (
    <div>
      {renderDeleteModal()}
      <div>
        <h3 className="text-3xl text-white font-bold tracking-tight mb-6">
          MY PROFILE
        </h3>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // handleSubmit();
        }}
        noValidate
      >
        <p className="pl-6 pb-2 font-bold">First name</p>
        <Input
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          onBlur={validateInput}
          error={inputError.firstName}
          placeholder="Enter your first name"
          className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 font-semibold"
        />
        <p className="pl-6 pb-2 font-bold">Last name</p>
        <Input
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          onBlur={validateInput}
          error={inputError.lastName}
          placeholder="Enter your last name"
          className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 font-semibold"
        />
        <p className="pl-6 pb-2 font-bold">Email address</p>
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          onBlur={validateInput}
          error={inputError.email}
          placeholder="Enter your email"
          className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 font-semibold"
        />
        <p className="pl-6 pb-2 font-bold">Password</p>
        <Input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          onBlur={validateInput}
          error={inputError.password}
          placeholder="Enter your password"
          className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 font-semibold"
        />
        <div className="mt-8 text-center">
          <button
            type="submit"
            onClick={() => handleSubmit()}
            className={`rounded-full bg-black py-4 px-6 text-sm font-semibold text-white outline outline-1  shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
              IsUpdateBtnLoading ? "cursor-not-allowed" : ""
            }`}
          >
            {IsUpdateBtnLoading && <LoaderSmall />}
            UPDATE
          </button>
        </div>
        <div className="mt-4 text-center">
          <button
            type="submit"
            onClick={() => setIsDeleteModal(true)}
            className="rounded-full bg-black py-4 px-6 text-sm font-semibold text-white outline outline-1  shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Delete account
          </button>
        </div>
        {/* <div className="mt-4 text-center">
          <Link to="/">How to cancel subscription?</Link>
        </div> */}
      </form>
    </div>
  );
};

export default MyProfile;
