import axios from "axios";
import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { baseURL } from "Utils";
import logo from "assets/image/ichilli_logo.png";
import Input from "components/Input";
import Swal from "sweetalert2";

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [inputError, setInputError] = useState({});
  const history = useHistory();
  const fetchData = async () => {
    try {
      const response = await axios.post(baseURL + "registerweb", {
        fname: formData.firstName,
        lname: formData.lastName,
        email: formData.email,
        password: formData.password,
      });
      history.push("/SignIn");
      // setData(response.data);
    } catch (error) {
      console.error("login ==> Error fetching data:", error);
    }
  };
  function checkValuesPresent(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          return false;
        }
      }
    }
    return true;
  }
  const handleSubmit = () => {
    if (checkValuesPresent(formData) && !checkValuesPresent(inputError)) {
      fetchData();
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please complete the form.",
        showConfirmButton: true,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setInputError((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };
  const validateInput = (e) => {
    const { name, value } = e.target;
    if (!formData[name]) {
      setInputError((prevData) => ({
        ...prevData,
        [name]: `${[name]} is required`,
      }));
    } else {
      setInputError((prevData) => ({
        ...prevData,
        [name]: "",
      }));
    }
  };
  return (
    <div>
      <div className="flex flex-col min-h-screen items-center justify-center w-screen bg-[url('assets/image/mainbg.jpg')] bg-cover bg-no-repeat bg-center">
        <div
          className="bg-ececec rounded-2xl flex flex-col md:flex-row w-11/12 text-black"
          style={{ maxWidth: "1500px" }}
        >
          <div className="bg-white flex items-center justify-center md:w-2/5 md:rounded-l-2xl md:rounded-r-none rounded-t-2xl">
            <img className="h-14 md:h-20 my-4" src={logo} alt="ichilli" />
          </div>
          <div className="flex md:w-2/5 flex-col justify-start w-100 md:m-auto px-8 py-12">
            <div className="text-xl md:text-3xl font-bold">
              Sign Up to iChillmusic
            </div>
            <div className="sm:col-span-4">
              <div className="mt-6">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  noValidate
                >
                  <Input
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    onBlur={validateInput}
                    error={inputError.firstName}
                    placeholder="Enter your first name"
                    className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 font-semibold"
                  />
                  <Input
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    onBlur={validateInput}
                    error={inputError.lastName}
                    placeholder="Enter your last name"
                    className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 font-semibold"
                  />
                  <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={validateInput}
                    error={inputError.email}
                    placeholder="Enter your email"
                    className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 font-semibold"
                  />
                  <Input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={validateInput}
                    error={inputError.password}
                    placeholder="Enter your password"
                    className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 font-semibold"
                  />
                  <Input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    onBlur={validateInput}
                    error={inputError.confirmPassword}
                    placeholder="Enter your password"
                    className="w-full block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 font-semibold"
                  />
                  <button
                    type="submit"
                    className="rounded-full bg-black w-full py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    SIGN UP
                  </button>
                </form>
              </div>
            </div>
            <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
              <p className="mx-4 mb-0 text-center font-semibold text-slate-500">
                Or
              </p>
            </div>
            <Link to="/SignIn" className="">
              <button
                type="submit"
                className="rounded-full bg-black w-full py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Already have an account?
              </button>
            </Link>
          </div>
        </div>
        <div className="font-medium p-6">
          Copyright © 2023 iChillmusic . All rights reserved | Term of use |
          Privacy Policy
        </div>
      </div>
    </div>
  );
};

export default Register;
