import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { setDashboard, setPlayList } from "stores/rootReducer";
import { Icon } from "components/Icons";
import Loader from "components/Loader";
import MyModal from "components/Modal";
import { useState } from "react";
import axios from "axios";
import { baseURL } from "Utils";
import Input from "components/Input";
import Swal from "sweetalert2";
import { Droppable } from "react-beautiful-dnd";

export default function PlaylistMenu() {
  const dispatch = useDispatch();
  const { dashboardList } = useSelector((state) => state.rootReducer);

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [inputError, setInputError] = useState();
  const [SelectedPlaylist, setSelectedPlaylist] = useState();
  const [newPlaylistName, setNewPlaylistName] = useState();

  const matchedPlaylist =
    dashboardList &&
    dashboardList?.find((playlist) => {
      return playlist.title === "My playlists";
    });

  const handleAlbumClick = (playlist) => {
    dispatch(setPlayList(playlist));
    localStorage.setItem("playlist", JSON.stringify(playlist));
  };
  const SetUserDashboardFunc = () => {
    axios
      .post(baseURL + "api/userDashboard", {
        user_id:
          localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
      })
      .then((response) => {
        dispatch(setDashboard(response?.data?.result));
      })
      .catch((err) => console.error("error:", err));
  };
  const handleDeleteClick = () => {
    if (SelectedPlaylist) {
      // console.log("SelectedPlaylist =", SelectedPlaylist);
      axios
        .post(baseURL + "api/addUpdatePlayList", {
          user_id:
            localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
          songid: 0,
          pid: SelectedPlaylist?.PlayListId,
          playlistflag: 2, // 2 for delte playlist, 1 for add to playlist
        })
        .then((response) => {
          if (response?.data?.status == "success") {
            SetUserDashboardFunc();
            setSelectedPlaylist("");
            setIsDeleteModal(false);
            Swal.fire({
              icon: "success",
              title: "Playlist deleted successfully",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
          } else {
            setSelectedPlaylist("");
            setIsDeleteModal(false);
            Swal.fire({
              icon: "error",
              title: "failed.",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => console.error("error:", err));
    }
  };
  const handleChange = (e) => {
    const { value } = e.target;
    setNewPlaylistName(value);
    setInputError();
  };
  const validateInput = (e) => {
    const { name, value } = e.target;
    if (!newPlaylistName) {
      setInputError(`Playlist name is required`);
    } else {
      setInputError();
    }
  };
  const handleEditClick = () => {
    if (SelectedPlaylist && newPlaylistName) {
      // console.log("SelectedPlaylist =", SelectedPlaylist);
      axios
        .post(baseURL + "api/renamePlayList", {
          UserId:
            localStorage.getItem("USERID") || sessionStorage.getItem("USERID"),
          PlayListId: SelectedPlaylist?.PlayListId,
          PlayListName: newPlaylistName,
        })
        .then((response) => {
          if (response?.data?.status.toLowerCase() == "success") {
            SetUserDashboardFunc();
            setSelectedPlaylist("");
            closeEditModal();
            Swal.fire({
              icon: "success",
              title: "Playlist renamed successfully",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
          } else {
            setSelectedPlaylist("");
            closeEditModal();
            Swal.fire({
              icon: "error",
              title: "failed.",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => console.error("error:", err));
    } else {
      setInputError(`Playlist name is required, please try again`);
    }
  };
  const closeEditModal = () => {
    setIsEditModal(false);
    setInputError();
    setNewPlaylistName();
  };
  const renderDeleteModal = () => {
    return (
      <MyModal
        showModel={isDeleteModal}
        setModel={setIsDeleteModal}
        title="Please Confirm"
      >
        <p className=" pt-2">Are you sure you want to delete this playlist?</p>
        <div className="w-full pt-4">
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => setIsDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => handleDeleteClick()}
          >
            Delete
          </button>
        </div>
      </MyModal>
    );
  };
  const renderEditModal = () => {
    return (
      <MyModal
        showModel={isEditModal}
        setModel={() => closeEditModal()}
        title="Rename Playlist"
      >
        <p className="font-bold py-2">Please enter new playlist name.</p>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            // handleEditClick();
          }}
          noValidate
        >
          <Input
            name="playlistName"
            value={newPlaylistName}
            onChange={handleChange}
            onBlur={validateInput}
            error={inputError}
            placeholder={SelectedPlaylist?.PlayListName}
            className="w-full mt-4 block flex-1 shadow-sm rounded-full border-0 bg-white py-4 pl-6 text-gray-900 placeholder:text-gray-400 sm:text-sm md:text-md font-bold sm:leading-6 ring-1 ring-inset ring-gray-300"
          />
        </form>
        <div className="w-full pt-4">
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => closeEditModal()}
          >
            Cancel
          </button>
          <button
            className="p-2 rounded-full bg-black mr-2 py-4 px-6 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => handleEditClick()}
            type="submit"
          >
            Rename
          </button>
        </div>
      </MyModal>
    );
  };

  // console.log("matchedPlaylist", matchedPlaylist);
  if (dashboardList && !matchedPlaylist?.records?.length > 0) {
    return <p className="text-link text-sm pl-6">no data</p>;
  }
  return (
    <nav className="flex relative">
      {renderDeleteModal()}
      {renderEditModal()}
      <ul className="w-full">
        {matchedPlaylist &&
          matchedPlaylist?.records?.map((playlist) => (
            <Droppable
              droppableId={`droppableInPlaylist-${JSON.stringify(playlist)}`}
            >
              {(provided, snapshot) => (
                <li
                  className={`group flex items-center pl-4 py-2 pr-24 ${
                    snapshot?.isDraggingOver
                      ? "border border-dashed rounded-lg border-[#26C281] "
                      : ""
                  }`}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <NavLink
                    key={playlist.PlayListId}
                    to={`/PlayList/${playlist.PlayListName}`}
                    onClick={() => handleAlbumClick(playlist)}
                    className="h-8 flex items-center text-sm font-bold text-link hover:text-white transition-all"
                  >
                    {/* {console.log("snapshot", snapshot)} */}
                    <span className="mr-4">
                      <Icon name="playlistIcon" />
                    </span>
                    <span className="text-link group-hover:text-white text-sm font-semibold transition-all">
                      {playlist.PlayListName}
                    </span>
                  </NavLink>

                  <div
                    // className="absolute hidden right-0 group-hover:flex transition-all"
                    className="absolute flex md:hidden right-4 group-hover:flex transition-all"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span
                      className="pr-2 hover:scale-105 cursor-pointer"
                      onClick={async () => {
                        // setIsEditModal(true);
                        // setNewPlaylistName();
                        // setSelectedPlaylist(playlist);
                        try {
                          await navigator.clipboard.writeText(
                            `https://upgrade.ichillmusic.com/api/getPlayListByImport?playlistUniqueId=${
                              playlist?.playlistUniqueCode
                            }&UserId=${
                              localStorage.getItem("USERID") ||
                              sessionStorage.getItem("USERID")
                            }`
                          );
                          Swal.fire({
                            icon: "success",
                            title: "Share link copied to your clipboard.",
                            showConfirmButton: false,
                            timer: 2500,
                            timerProgressBar: true,
                          });
                          // setCopySuccess(true)
                        } catch (error) {
                          console.error("Unable to copy to clipboard:", error);
                        }
                      }}
                    >
                      <Icon name="share" />
                    </span>
                    <span
                      className="pr-2 hover:scale-105 cursor-pointer"
                      onClick={() => {
                        setIsEditModal(true);
                        setNewPlaylistName();
                        setSelectedPlaylist(playlist);
                      }}
                    >
                      <Icon name="edit" />
                    </span>
                    <span
                      className="hover:scale-105 cursor-pointer"
                      onClick={() => {
                        setIsDeleteModal(true);
                        setSelectedPlaylist(playlist);
                      }}
                    >
                      <Icon name="delete" />
                    </span>
                  </div>
                </li>
              )}
            </Droppable>
          ))}
      </ul>
    </nav>
  );
}
