import axios from "axios";
import PlaylistItemBar from "components/PlaylistItemBar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { baseURL } from "Utils";
import { setShuffle, setSongList } from "stores/rootReducer";
import { setLoading } from "stores/rootReducer";
import Loader from "components/Loader";
import RenderAnimatedList from "components/RenderAnimatedList";
import { useState } from "react";
import { setCurrent, setCurrentPlaylist } from "stores/player";
// import multiselectOpenerIcon from "assets/image/Group11157.svg";
// import multiselectOpenerIconActive from "assets/image/Group11157_active.svg";
// import multiselectOpenerIcon from "public/ichillwebreactjs/assets/image/Group11157.svg";
// import multiselectOpenerIconActive from "public/ichillwebreactjs/assets/image/Group11157_active.svg";
import MyModal from "components/Modal";
import AddToPlaylistModal from "components/AddToPlaylistModal/AddToPlaylistModal";
import Task from "components/Task";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Icon } from "components/Icons";

const PlayList = () => {
  const { playListName } = useParams();
  const dispatch = useDispatch();
  // const { currentRepeatState } = useSelector((state) => state.player);
  const { playList, songList, isLoading, isShuffle, dashboardList } =
    useSelector((state) => state.rootReducer);
  const { current } = useSelector((state) => state.player);

  const [AllSongList, setAllSongList] = useState(songList || []);
  const [SelectedSongList, setSelectedSongList] = useState([]);
  // console.log("SelectedSongList", SelectedSongList);
  const [selectAll, setSelectAll] = useState(false);

  const [IsMultiSelection, setIsMultiSelection] = useState(false);
  const [AddToPlayListClickForModelOpen, setAddToPlayListClickForModelOpen] =
    useState(false);

  const currentPlaylist =
    playList || JSON.parse(localStorage.getItem("playlist"));
  const userId =
    localStorage.getItem("USERID") || sessionStorage.getItem("USERID");

  // const ShouldShowDeleteBtn =
  //   dashboardList &&
  //   dashboardList
  //     ?.find((playlist) => {
  //       return playlist.title === "My playlists";
  //     })
  //     ?.records?.some(
  //       (item) => item?.PlayListName == currentPlaylist?.PlayListName
  //     );

  useEffect(() => {
    if (AllSongList) {
      const uniqueValues = new Set([
        ...SelectedSongList,
        ...AllSongList.map((option) => option.SongId.toString()),
      ]);
      setSelectAll(
        SelectedSongList.length == [...uniqueValues].length &&
          SelectedSongList.length != 0
      );
    }
  }, [SelectedSongList]);
  useEffect(() => {
    if (!isShuffle) {
      setAllSongList(songList);
    }
  }, [songList]);
  useEffect(() => {
    setSelectedSongList([]);
    setSelectAll(false);
  }, [IsMultiSelection]);
  useEffect(() => {
    setAllSongList();
    if (isShuffle) {
      setAllSongList(shuffleArray(songList));
    } else {
      setAllSongList(songList);
    }
    setSelectedSongList([]);
    updatePlaylist();
  }, [isShuffle]);

  useEffect(() => {
    if (isShuffle) {
      if (AllSongList?.length) {
        dispatch(setCurrent(AllSongList[0]));
        updatePlaylist();
      }
    }
  }, [AllSongList]);

  useEffect(() => {
    setIsMultiSelection(false);
    setSelectedSongList([]);
    if (isShuffle) {
      dispatch(setShuffle(false));
      setAllSongList(null);
    }
    dispatch(setLoading(true));
    let url;
    if (currentPlaylist && userId) {
      if (currentPlaylist.UserPlayListid) {
        url =
          baseURL +
          "api/userSongs" +
          "?user_id=" +
          userId +
          "&PlayListId=" +
          currentPlaylist.UserPlayListid;
      } else if (currentPlaylist.PlayListId) {
        url =
          baseURL +
          "api/userSongs" +
          "?user_id=" +
          userId +
          "&PlayListId=" +
          currentPlaylist.PlayListId;
      } else if (currentPlaylist.CategoryId) {
        url =
          baseURL +
          "api/userSongs" +
          "?user_id=" +
          userId +
          "&CategoryId=" +
          currentPlaylist.CategoryId;
      } else if (currentPlaylist.AlbumId) {
        url =
          baseURL +
          "api/userSongs" +
          "?user_id=" +
          userId +
          "&albumId=" +
          currentPlaylist.AlbumId;
      }

      axios
        .post(url)
        .then((response) => {
          // console.log("header response", response);
          dispatch(setSongList(response?.data?.result));
          dispatch(setLoading(false));
        })
        .catch((err) => console.error("error:", err));
      dispatch(setLoading(false));
    }
    return () => {
      dispatch(setSongList(""));
      dispatch(setLoading(false));
    };
  }, [playListName]);

  const reFetchSongs = () => {
    setIsMultiSelection(false);
    setSelectedSongList([]);
    if (isShuffle) {
      dispatch(setShuffle(false));
      setAllSongList(null);
    }
    dispatch(setLoading(true));
    let url;
    if (currentPlaylist && userId) {
      if (currentPlaylist.UserPlayListid) {
        url =
          baseURL +
          "api/userSongs" +
          "?user_id=" +
          userId +
          "&PlayListId=" +
          currentPlaylist.UserPlayListid;
      } else if (currentPlaylist.PlayListId) {
        url =
          baseURL +
          "api/userSongs" +
          "?user_id=" +
          userId +
          "&PlayListId=" +
          currentPlaylist.PlayListId;
      } else if (currentPlaylist.CategoryId) {
        url =
          baseURL +
          "api/userSongs" +
          "?user_id=" +
          userId +
          "&CategoryId=" +
          currentPlaylist.CategoryId;
      } else if (currentPlaylist.AlbumId) {
        url =
          baseURL +
          "api/userSongs" +
          "?user_id=" +
          userId +
          "&albumId=" +
          currentPlaylist.AlbumId;
      }

      axios
        .post(url)
        .then((response) => {
          dispatch(setSongList(response?.data?.result));
          dispatch(setLoading(false));
        })
        .catch((err) => {
          console.error("error:", err);
          dispatch(setLoading(false));
        });
    }
  };

  function shuffleArray(array) {
    const newArray = [...array]; // Create a new array to avoid modifying the original
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }
  const updatePlaylist = () => {
    // console.log("updating..... currentPlaylist");
    dispatch(setCurrentPlaylist(isShuffle ? AllSongList : AllSongList));
  };
  // const checkIfAllChecked = () => {
  //   // const uniqueValues = new Set([
  //   //   ...SelectedSongList,
  //   //   ...AllSongList.map((option) => option.SongId.toString()),
  //   // ]);
  //   // setTimeout(() => {
  //   console.log("uniqueValues", SelectedSongList);
  //   // setSelectAll(SelectedSongList.length == [...uniqueValues].length);
  //   // }, 1000);
  // };

  const handleSongCheckBoxChange = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      // If the checkbox is checked, add the item to the array (without duplicates)
      setSelectedSongList((prevCheckedItems) => [
        ...new Set([...prevCheckedItems, id.toString()]),
      ]);
    } else {
      // If the checkbox is unchecked, remove the item from the array
      setSelectedSongList((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item != id)
      );
    }
    // checkIfAllChecked();
  };

  const handleToggleAll = () => {
    if (selectAll) {
      setSelectedSongList([]);
    } else {
      // setSelectedSongList(AllSongList.map((option) => option.SongId));
      // Use a Set to filter out duplicate values
      const uniqueValues = new Set([
        ...SelectedSongList,
        ...AllSongList.map((option) => option.SongId.toString()),
      ]);
      setSelectedSongList([...uniqueValues]);
    }
    setSelectAll(!selectAll);
  };
  const getImageSrcForPlaylist = () => {
    if (
      current &&
      AllSongList.some((song) =>
        song.SongTitle.toLowerCase().includes(current?.SongTitle?.toLowerCase())
      )
    ) {
      return (
        current?.AlbumImage ||
        currentPlaylist?.CategoryImage ||
        currentPlaylist?.AlbumImage
      );
    } else if (AllSongList?.length > 0) {
      return (
        AllSongList[0]?.AlbumImage ||
        currentPlaylist?.CategoryImage ||
        currentPlaylist?.AlbumImage
      );
    } else {
      return currentPlaylist?.CategoryImage || currentPlaylist?.AlbumImage;
    }
  };
  console.log(AllSongList);
  const calculateTotalTime = (data) => {
    // Calculate the total time in seconds
    const totalTimeInSeconds = data.reduce(
      (total, item) => total + parseTimeToSeconds(item.OriginalTrackTime),
      0
    );

    // Convert the total time in seconds back to HH:MM:SS format
    const hours = Math.floor(totalTimeInSeconds / 3600);
    const minutes = Math.floor((totalTimeInSeconds % 3600) / 60);
    const seconds = totalTimeInSeconds % 60;

    const totalTimeFormatted = `${
      hours.toString().padStart(2, "0") == "00"
        ? ""
        : hours.toString().padStart(2, "0") + " Hour "
    }${
      minutes.toString().padStart(2, "0") == "00"
        ? ""
        : minutes.toString().padStart(2, "0") + " Minutes"
    }`;

    console.log("Total Time: " + totalTimeFormatted);
    return totalTimeFormatted;
  };
  // Function to parse a time string (HH:MM:SS or MM:SS) and return the total number of seconds
  function parseTimeToSeconds(timeStr) {
    const parts = timeStr.split(":");
    if (parts.length === 3) {
      // HH:MM:SS format
      return (
        parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2])
      );
    } else if (parts.length === 2) {
      // MM:SS format
      return parseInt(parts[0]) * 60 + parseInt(parts[1]);
    }
    return 0;
  }
  if (isLoading || (!AllSongList && !isLoading)) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <div>
      <AddToPlaylistModal
        showModel={AddToPlayListClickForModelOpen}
        setModel={setAddToPlayListClickForModelOpen}
        item={SelectedSongList}
        isMultiSong={true}
        setIsMultiSelection={setIsMultiSelection}
        playList={playList}
        reFetchSongs={reFetchSongs}
      />
      <div>
        <div className="flex items-end my-4">
          <div className="h-24 w-24 mx-4 border rounded-md">
            <img
              className="rounded-md"
              style={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
              }}
              src={getImageSrcForPlaylist()}
            />
          </div>
          <h3 className="text-3xl text-white font-bold tracking-tight">
            {playListName}
          </h3>
        </div>
      </div>
      {AllSongList?.length > 0 && (
        <>
          <div className="flex w-full flex-row items-center mb-4 md:hidden">
            {/* // mobile only */}
            {SelectedSongList?.length > 0 && (
              <>
                <button
                  className="rounded-full bg-card hover:bg-card py-2 px-6 text-sm font-semibold text-white shadow-sm hover:scale-105 mr-2"
                  onClick={() => setAddToPlayListClickForModelOpen(true)}
                >
                  Add to Playlist
                </button>
              </>
            )}
            {IsMultiSelection && (
              <button
                className="rounded-full bg-card hover:bg-card py-2 px-6 text-sm font-semibold text-white shadow-sm hover:scale-105 mr-2"
                onClick={() => setIsMultiSelection(false)}
              >
                Cancel
              </button>
            )}
          </div>
          <div className="flex w-full flex-row items-center mb-4 h-[2rem]">
            <div className="flex w-full flex-row items-center multiSelectOptions">
              {IsMultiSelection && (
                <button
                  className="flex flex-row items-center rounded-full bg-card hover:bg-card py-2 pl-2 pr-4 text-sm font-semibold text-white shadow-sm hover:scale-105"
                  onClick={() => {}}
                >
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleToggleAll}
                  />
                  <span className="pl-2">select all</span>
                </button>
              )}
              {SelectedSongList?.length > 0 && (
                <>
                  <button
                    className="rounded-full bg-card hover:bg-card py-2 px-6 text-sm font-semibold text-white shadow-sm hover:scale-105 ml-2 hidden md:flex"
                    onClick={() => setAddToPlayListClickForModelOpen(true)}
                  >
                    Add to Playlist
                  </button>
                </>
              )}
              {IsMultiSelection && (
                <button
                  className="rounded-full bg-card hover:bg-card py-2 px-6 text-sm font-semibold text-white shadow-sm hover:scale-105 ml-2 hidden md:flex"
                  onClick={() => setIsMultiSelection(false)}
                >
                  Cancel
                </button>
              )}
            </div>
            <div className="flex flex-row-reverse mr-4">
              <button
                className="hover:scale-105 transition-all"
                onClick={() => setIsMultiSelection(!IsMultiSelection)}
              >
                {/* <img
                  src={
                    IsMultiSelection
                      ? "/public/ichillwebreactjs/assets/image/Group11157_active.svg"
                      : "/public/ichillwebreactjs/assets/image/Group11157.svg"
                  }
                /> */}
                {IsMultiSelection ? (
                  <Icon name="multiSelectActive" />
                ) : (
                  <Icon name="multiSelect" />
                )}
              </button>
            </div>
          </div>
        </>
      )}

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {AllSongList?.length == 0 ? "No song Found !!" : ""}
        {AllSongList?.length > 0 && (
          // <DragDropContext onDragEnd={onDragEnd}>
          <>
            <Droppable droppableId="droppableInPlaylistForSort">
              {(provided, snapshot) => (
                <div
                  className="w-full"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {AllSongList?.map((item, index) => (
                    <Draggable
                      key={item.UserSongListId || item.SongId}
                      draggableId={`item-${JSON.stringify(item)}`}
                      // index={`${index}-1`}
                      index={index}
                      data={JSON.stringify(item)}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          data={JSON.stringify(item)}
                        >
                          <RenderAnimatedList
                            item={item}
                            index={index}
                            updatePlaylist={updatePlaylist}
                            IsMultiSelection={IsMultiSelection}
                            handleSongCheckBoxChange={handleSongCheckBoxChange}
                            SelectedSongList={SelectedSongList}
                            playList={playList}
                            reFetchSongs={reFetchSongs}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className="text-center">
              {AllSongList?.length} Songs | {calculateTotalTime(AllSongList)}
            </div>
          </>
          // </DragDropContext>
        )}
      </div>
    </div>
  );
};

export default PlayList;
